import  React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import {BsSliders} from 'react-icons/bs';
import {GiRibbonMedal} from 'react-icons/gi';
import ExploreHeader from '../../influencer/explore/components/ExploreHeader';
import ExploreSort from '../../influencer/explore/components/ExploreSort';
import ExploreCard from '../../influencer/explore/components/ExploreCard';
import EmptyState from '../../../shared/components/EmptyState';
import {Pagination} from '../../../shared/components/Pagination';
import {useHistory, useLocation} from 'react-router-dom';
import {IExploreFilterParams, IExploreItem} from '../../influencer/explore/dto/Explore';
import usePagination from '../../../shared/hooks/usePagination';
import {useTranslation} from 'react-i18next';
import {AgencyService} from '../service';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {InfluencerExploreApi} from '../../influencer/explore/service';
import {AgencyInfluencerOfferModal} from '../offer/modal';
import ExploreFilterModal from '../../influencer/explore/components/ExploreFilterModal';

interface IStateProps {
    isBookmark: boolean;
    isCharity: boolean;
}

export function AgencyExplore() {
    const [exploreList, setExploreList] = useState<IExploreItem[]>([]);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [nbPickedOffers, setNbPickedOffers] = useState(0);
    const [nbReverseOffers, setNbReverseOffers] = useState(0);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    const [updateExploreList, setUpdateExploreList] = useState(false);
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);
    const {state} = useLocation();
    const isBookmarkActive = !!(state as unknown as IStateProps)?.isBookmark;
    const isCharityActive = !!(state as unknown as IStateProps)?.isCharity;
    const {t} = useTranslation();
    const {
        setTotalPages,
        totalPages,
        handlePages,
        setFilterParams: setExploreFilters,
        filterParams: exploreFilters,
    } = usePagination({
        perPage: 10,
        otherKeys: {sortBy: 'date', influencerId: selectedInfluencer?.id, sortType: 'DESC', isCharity: isCharityActive},
    });

    useEffect(() => {
        AgencyService.getExploreCampaigns(exploreFilters)
            .then(response => {
                setExploreList(response.data);
                setTotalPages(Math.ceil(response.count / response.perPage));
                setNbPickedOffers && setNbPickedOffers((response as any)?.hasPicked);
                setNbReverseOffers && setNbReverseOffers((response as any)?.reverseCount);
                // history.replace({state: {}});
            }).catch((error) => {
            setExploreList([]);
            setTotalPages(0);
            setNbPickedOffers(0);
            setNbReverseOffers(0);
            ErrorToast(error);
        });
    }, [exploreFilters, updateExploreList]);

    useEffect(() => {
        selectedInfluencer?.id !== exploreFilters?.influencerId && setExploreFilters((prev: any) => ({
            ...prev,
            influencerId: selectedInfluencer?.id,
            page: 1,
        }));
    }, [selectedInfluencer?.id]);

    const handleAddToFavorite = (exploreId: number) => {
        const itemToUpdate = exploreList?.find(exploreItem => exploreItem.id === exploreId);
        InfluencerExploreApi.addToFavorites(exploreId, itemToUpdate?.isFavorite ? 'put' : 'post').then(response => {
            if (response) {
                const isAdd = (response as unknown as IExploreItem[])?.some(explore => explore.id === exploreId);
                SuccessToast(t(isAdd ? 'influencer.explore.addToFavoriteSuccess' : 'influencer.explore.removeFromFavoriteSuccess'));
                setUpdateExploreList(prevState => !prevState);
            }
        }).catch(error => ErrorToast(error));
    };

    return (
        <>
            <ExploreFilterModal showFilterModal={showFilterModal} handleClose={() => setShowFilterModal(false)}
                                exploreFilters={exploreFilters} setExploreFilters={setExploreFilters}
                                isAgencyPage={true} nbPickedOffers={nbPickedOffers} nbReverseOffers={nbReverseOffers}/>
            <AgencyInfluencerOfferModal show={showOfferModal}
                                        setShowModal={setShowOfferModal}
                                        campaignId={campaignId}
                                        updateList={setUpdateExploreList}
            />
            <h1></h1>
            <div className="row">
                <Col xs={6} className="d-md-none">
                    <Button variant={!showFilterModal ? 'outline-secondary' : 'primary'}
                            onClick={() => setShowFilterModal(true)}>
                        <BsSliders size={20}/>
                    </Button>
                </Col>
                <Col lg={8} className="d-none d-md-flex align-items-center pl-0">
                    <ExploreHeader exploreFilters={exploreFilters} nbPickedOffers={nbPickedOffers}
                                   setExploreFilters={setExploreFilters}
                                   nbReverseOffers={nbReverseOffers} isAgencyPage={true}/>
                </Col>
                <ExploreSort exploreFilters={exploreFilters}
                             setExploreFilters={setExploreFilters} isAgencyPage={true}/>
            </div>
            <Row className="mt-3 mb-md-5">
                {!!nbPickedOffers ? <Col md={7}>
                    <Alert variant="warning" className="box-shadow">
                        <GiRibbonMedal size={20}/>
                        {t('influencer.explore.ivPicked')}
                    </Alert>
                </Col> : ''}
                {!!exploreList?.length ? exploreList?.map((exploreItem) => <div className="col-lg-6 my-2"
                                                                                key={exploreItem?.id}>
                    <ExploreCard setShowModal={setShowOfferModal} exploreItem={exploreItem} setExploreFilters={setExploreFilters}
                                 handleAddToFavorite={handleAddToFavorite} setCampaignId={setCampaignId}
                                 isAgencyCard={true}/>
                </div>) : <div className="card">
                    <div className="card-body">
                        <EmptyState/>
                    </div>
                </div>}
                {totalPages <= 1 ? '' :
                    <Col xs={12} className="d-flex justify-content-end pb-lg-0">
                        <Pagination page={exploreFilters?.page} totalPages={totalPages}
                                    handlePagination={handlePages}/>
                    </Col>
                }

            </Row>
        </>
    );
}
