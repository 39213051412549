import React, {SetStateAction, useState} from 'react';
import {Alert, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiCopy} from 'react-icons/bi';
import {BsDot} from 'react-icons/bs';
import {FaCalendarAlt, FaEdit} from 'react-icons/fa';
import {FiInfo} from 'react-icons/fi';
import {TiWarningOutline} from 'react-icons/ti';
import {useDispatch, useSelector} from 'react-redux';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import CustomDatePicker from '../../../../shared/components/DatePicker';
import {formatDate, gePlatformIcon, removeTimeZoneFromDate} from '../../../../shared/functions/Functions';
import {plannerAgency} from '../../../../store/agency/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {
    statusesToEditTrackinglink
} from '../../../brand/campaign/manage/calendar/components/modal/CalendarManageLeftCard';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {plannedStatuses} from '../../../influencer/reporting/components/ReportingCampaignCard';
import {AgencyPlannerService} from '../service';

interface IProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
}

const LeftPlannerCard = ({state, setState}: IProps) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {filterParams} = useSelector((state: IAllStates) => state.plannerAgency);
    const [isRescheduleClicked, setIsRescheduleClicked] = useState(false);
    const reschedulePostRequest = () => {
        AgencyPlannerService.reschedulePostRequest(state?.id, state?.postingDate).then(response => {
            if (response) {
                SuccessToast(t('general.toasts.successRescheduled'));
                setIsRescheduleClicked(false);
                setState(prev => ({...prev, reschedule: response?.[0]?.reschedule}));
                dispatch(plannerAgency.actions.setFilterParams({
                    updateCount: (filterParams?.updateCount ?? 0) + 1
                }));
            }
        }).catch(error => ErrorToast(error));
    };
    const handleChangeTimepicker = (newValue: string | undefined | null, name: string) => {
        setState(prev => ({...prev, [name]: newValue}));
    };
    const handleCopy = (value: string) => {
        SuccessToast(t('general.button.copied'));
        navigator.clipboard.writeText(value);
    };
    const hasWarning = plannedStatuses.includes(state?.status);
    return (
        <div>
            <div className="card border-primary">
                <div className="card-body p-3 d-flex align-items-center">
                    <CustomImageHandler photoPath={state?.user?.profilePhoto?.thumbnail}
                                        classes="middle-rounded-logo"
                                        altTag={'User Profile'}/>
                    <h6 className="mb-0 ml-2">
                        {state?.user?.displayName ?? '-'}
                    </h6>
                </div>
            </div>
            <div className="mt-3 mb-2">
                <h6 className="text-muted">Placement ID: #{state?.id}</h6>
            </div>
            <div className="card">
                <div className="card-body p-3">
                    {gePlatformIcon({
                        iconPath: state?.offerItem?.socialPlatform?.path,
                        platformName: t(`general.platforms.${state?.offerItem?.socialPlatform?.name}`)
                    })}
                </div>
            </div>
            <div className="card mt-2">
                <div className="card-body p-3">
                    <div className="d-flex align-items-center text-primary justify-content-between">
                        {isRescheduleClicked ?
                            <div className="d-flex align-items-end ">
                                <CustomDatePicker
                                    disableFrom={removeTimeZoneFromDate(state?.offerItem?.offer?.campaign?.publishFrom ?? '')}
                                    disableTo={removeTimeZoneFromDate(state?.offerItem?.offer?.campaign?.publishTo ?? '')}
                                    value={state?.postingDate}
                                    onChange={(e) => handleChangeTimepicker(e as unknown as string, 'postingDate')}
                                    label={t('general.button.reschedule')}/>
                                <Button variant="primary" onClick={reschedulePostRequest}
                                        className="w-fit ml-1">
                                    {t('general.button.save')}
                                </Button>
                            </div> :
                            <h6 className="mb-0">
                                {state?.postingDate ? formatDate(state?.postingDate) : '-'}
                                <FaCalendarAlt className="text-primary font-16 ml-2"/>
                            </h6>
                        }
                        {(statusesToEditTrackinglink.includes(state?.status)) &&
                            <div className={'position-relative'}>
                                {!!state?.reschedule?.id &&
                                    <BsDot className={'text-danger notification-bullet-sm'} style={{right: -10}}
                                           size={40}/>}
                                <FaEdit
                                    onClick={() => setIsRescheduleClicked(prevState => !prevState)}
                                    className="font-16 cursor-pointer"/>
                            </div>
                        }
                    </div>
                    <div>{t('brand.campaign.manage.postingDate')}</div>
                    {!!state?.reschedule?.id && <div className="mt-1">
                                <span className="text-primary">
                                    {formatDate(state?.reschedule?.postingDate)}
                                </span>
                        <div>{t('brand.campaign.manage.requestedDate')}</div>
                    </div>}
                </div>
            </div>


            {<div className="card mt-2">
                <div className="card-body p-3">
                    <h6 className="d-flex mb-0 align-items-center text-primary">
                        {state?.feedBackLoopDate ? formatDate(state?.feedBackLoopDate) : '-'}
                        <FaCalendarAlt className="text-primary font-16 ml-2"/>
                    </h6>
                    <div>
                        {t('brand.campaign.manage.feedBackLoopEnddate')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="impressions-tooltip">
                                            {t(`brand.campaign.manage.feedBackLoopEnddateTooltip`)}
                                        </Tooltip>}>
                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>}
            <div className="card mt-3">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <h6 className={'mb-0'}>
                            {t('influencer.planner.campaignHashtags')}
                        </h6>
                        {!!(state?.offerItem?.offer?.campaign?.hashtags) && (
                            <BiCopy className="copy-icon ml-1 text-muted"
                                    onClick={() => handleCopy(state?.offerItem?.offer?.campaign?.hashtags ?? '')}/>
                        )}
                    </div>
                    <div className="mt-2 word-break-word">
                        {!!state?.offerItem?.offer?.campaign?.hashtags?.length ? (state?.offerItem?.offer?.campaign?.hashtags?.split(' '))?.map((item, i) =>
                                <span key={i} className={`${i > 0 ?
                                    'pl-2' : ''}`}>{item}</span>) :
                            <span className="text-muted">-</span>}

                    </div>
                    {!!(state?.offerItem?.offer?.campaign?.profileTags) && (
                        <div className="mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h6 className={'mb-0'}>
                                    Tagging
                                </h6>
                                <BiCopy className="copy-icon ml-1 text-muted"
                                        onClick={() => handleCopy(state?.offerItem?.offer?.campaign?.profileTags ?? '')}/>
                            </div>
                            <div className="mt-2 word-break-word">
                                {!!state?.offerItem?.offer?.campaign?.profileTags?.length ? (state?.offerItem?.offer?.campaign?.profileTags?.split(' '))?.map((item, i) =>
                                        <span key={i} className={`${i > 0 ?
                                            'pl-2' : ''}`}>{item}</span>) :
                                    <span className="text-muted">-</span>}

                            </div>
                        </div>
                    )}

                    {!!state?.trackingLink && <div className="mt-3">
                        <div className="d-flex align-items-start justify-content-between">
                            <h6 className={'mb-0'}>
                                {t('influencer.reporting.trackingLink')}
                            </h6>
                            {!!state?.trackingLink && (
                                <BiCopy className="copy-icon text-muted"
                                        onClick={() => handleCopy(state?.trackingLink ?? '')}/>
                            )}
                        </div>
                        <div className="mt-1 text-muted">
                            {state?.trackingLink ?? '-'}
                        </div>
                    </div>}
                </div>
            </div>
            {hasWarning && <Alert variant="danger"
                                  className="box-shadow d-flex align-items-center w-100 mt-3">
                <TiWarningOutline size={20}/>
                <span className="ml-1">
                                            {t('general.waitingForApproval')}
                                        </span>
            </Alert>
            }
        </div>
    );
};

export default LeftPlannerCard;
