import axios, {CancelTokenSource} from 'axios';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiErrorCircle} from 'react-icons/bi';
import {FiInfo} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {plannerInfluencer} from '../../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {iManageAttachments, iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {ISelectedYearMonth} from '../index';
import {InfluencerPlannerService} from '../service';
import PreviewContentFiles from './preview/PreviewContentFiels';
import PreviewCopy from './preview/PreviewCopy';
import PreviewFeedback from './preview/PreviewFeedback';
import PreviewHashTags from './preview/PreviewHashTags';
import PreviewHeader from './preview/PreviewHeader';

interface IPreviewEventModalProps {
    isModalOpened: boolean;
    handleCloseModal: () => void;
    selectedYearMonth: ISelectedYearMonth;
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setUpdateReports?: React.Dispatch<SetStateAction<boolean>>;
}

const PreviewEventModal = ({
                               isModalOpened,
                               handleCloseModal,
                               setUpdateReports,
                               state,
                               setState,
                               selectedYearMonth,
                           }: IPreviewEventModalProps) => {
    const history = useHistory();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {events} = useSelector((reduxState: IAllStates) => reduxState.plannerInfluencer);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [hasTextInsideField, setHasTextInsideField] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<Blob[] | MediaSource[]>();
    const [selectedFile, setSelectedFile] = useState<iManageAttachments>();
    const [uploadData, setUploadData] = useState({
        loaded: 0,
        from: 0,
    });
    const [source, setSource] = useState<undefined | CancelTokenSource>(axios.CancelToken.source());
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setState(prev => ({...prev, [name]: value}));
    };
    const progressHandler = (e: ProgressEvent) => {
        setUploadData({
            loaded: e.loaded,
            from: e.total
        })
    }

    const cancelApiCall = () => {
        source?.cancel('API request cancelled');
        setSource(undefined);
    }

    const publishEvent = async (isPublishAndReport = false) => {
        await InfluencerPlannerService.updatePostingLink({
            postingDate: state?.postingDate,
            postingLink: state?.postingLink,
            offerItemEventId: state?.id,
            offerItemId: state?.offerItem?.id,
        }).then(response => {
            const copy = [...events];
            const indexToUpdate = copy?.findIndex(event => event.id === state?.id);
            copy[indexToUpdate] = response[0];
            handleCloseModal();
            SuccessToast(t('influencer.planner.successUpdated'));
            dispatch(plannerInfluencer.actions.setEvents(copy));
            if (isPublishAndReport) {
                history.push('/influencer/reporting', {
                    offerId: response[0]?.id
                })
            }
        }).catch(error => ErrorToast(error));
    };

    const handleUploadFiles = async () => {
        const formData = new FormData();

        if (!!filesToUpload?.length) {
            for (const item of filesToUpload) {
                formData.append('files', (item as string | Blob));
            }
        }
        setIsFileUploading(true);
        await InfluencerPlannerService.uploadAttachment(formData, state?.id, source?.token as any, (e: ProgressEvent) => progressHandler(e)).then(response => {
            setState(prev => ({...prev, attachments: response?.attachments}));
            setFilesToUpload([]);
            setUpdateReports && setUpdateReports(prev => !prev);
            setSelectedFile(undefined);
            setUploadData({
                loaded: 0,
                from: 0
            })
            SuccessToast(t('influencer.planner.successUploadedFile'));
        }).catch(error => ErrorToast(error)).finally(() => setIsFileUploading(false));
    };
    const updateContent = async () => {
        await InfluencerPlannerService.updateCaption({
            eventId: state?.id,
            caption: state?.caption,
            storyline: state?.storyline,
        }).then(response => {
            handleCloseModal();
            setUpdateReports && setUpdateReports(prev => !prev);
            SuccessToast(t('influencer.planner.successUpdated'));
        }).catch(error => ErrorToast(error))
    }

    const navigateToReporting = () => {
        history.push('/influencer/reporting', {
            offerId: state?.id
        })
    }

    useEffect(() => {
        if (!!filesToUpload?.length) {
            handleUploadFiles();
        }
        // eslint-disable-next-line
    }, [filesToUpload?.length])
    return (
        <Modal
            show={isModalOpened}
            centered
            onHide={handleCloseModal}
            size="xl"
            backdrop={isFileUploading ? 'static' : undefined}
            keyboard={false}
        >
            {state?.id && <Modal.Body>
                <div className="row">
                    <PreviewHeader state={state} setState={setState} selectedYearMonth={selectedYearMonth}/>
                </div>
                <div className="row">
                    <PreviewCopy state={state} changeHandler={changeHandler}/>
                    <PreviewContentFiles uploadData={uploadData} filesToUpload={filesToUpload as Blob[] | MediaSource[]}
                                         selectedFile={selectedFile as iManageAttachments}
                                         setFilesToUpload={setFilesToUpload as React.Dispatch<SetStateAction<Blob[] | MediaSource[]>>}
                                         setSelectedFile={setSelectedFile}
                                         setIsFileUploading={setIsFileUploading} cancelApiCall={cancelApiCall}
                                         isFileUploading={isFileUploading}
                                         setUpdateReports={setUpdateReports}
                                         state={state} setState={setState}/>
                    <PreviewHashTags state={state}/>
                    <PreviewFeedback state={state} setState={setState} setHasTextInsideField={setHasTextInsideField}/>
                </div>
            </Modal.Body>}
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleCloseModal}>
                    {t('general.button.cancel')}
                </Button>
                {state?.status === 'approved' &&
                    <>
                        <Button variant="warning" onClick={() => publishEvent(true)} disabled={hasTextInsideField}>
                            Publish & Report
                        </Button>
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="info">
                                            {t('influencer.planner.info_tooltip')}
                                        </Tooltip>}>
                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </>
                }
                {['scheduled', 'feedback_loop'].includes(state?.status) &&
                    <Button variant="primary" onClick={updateContent}
                            disabled={(!state?.caption?.length && !state?.storyline?.length) || hasTextInsideField}>
                        {t('general.button.update')}
                    </Button>
                }
                {state?.status === 'reported' &&
                    <Button variant="success" onClick={navigateToReporting}
                            disabled={hasTextInsideField}>
                        {t('general.button.updateReport')}
                    </Button>
                }
                {state?.status === 'published' &&
                    <Button variant="warning" onClick={navigateToReporting}
                            disabled={hasTextInsideField}>
                        {t('general.button.report')}
                    </Button>
                }
                {hasTextInsideField && 
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="country-tooltip">
                                {t('general.deleteSend')}
                            </Tooltip>
                        }
                    >
                        <BiErrorCircle size={20} className="text-danger cursor-pointer"/>
                    </OverlayTrigger>
                }
            </Modal.Footer>
        </Modal>
    );
};

export default PreviewEventModal;
