import React from 'react'
import { Col, Row} from 'react-bootstrap';
import { UploadImage } from '../../../../../shared/components/UploadImage';
import { formatDate, isBetween } from '../../../../../shared/functions/Functions';
import { CustomImageHandler } from '../../../../../shared/components/CustomImageHandler';
import {Fancybox} from '../../../../../shared/components/Fancybox';
import { IoCloseSharp } from 'react-icons/io5';
import { User } from '../../../../../model/user/User';
import { t } from 'i18next';

interface IProfileKpiScreenshots {
    user: User;
    isEditable?: boolean;
    handleUploadFiles?: (files: Blob[] | MediaSource[]) => void;
    handleDeleteFile: (postId: number) => void;
    files: any;
    setFiles: (...args: any) => void;
}

export default function ProfileKpiScreenshots({
    user,
    isEditable = false,
    handleDeleteFile,
    files, 
    setFiles,
}: IProfileKpiScreenshots) {

    return (
        <div className="mb-4">
            <h6>
                {t('influencer.profile.audienceKPIScreenshots')}
            </h6>
            <span className="text-muted">
                {t('influencer.profile.audienceKPIScreenshots_desc')}
            </span>
            <Row className="mt-2">
                {user?.userKpiScreenshots?.map((screenshots) => <Col md={2} key={screenshots?.id} className="pl-md-0 my-1">
                    <div className="position-relative h-100">
                        {(isEditable && user?.userKpiScreenshots?.length > 3) &&
                            <div className="highlighted-post-cancel" onClick={() => handleDeleteFile(screenshots?.id)}>
                                <IoCloseSharp/>
                            </div>
                        }
                        <Fancybox options={{infinite: false}}>
                            <div
                                data-fancybox="gallery-audience-kpi"
                                className="cursor-pointer w-100"
                                data-src={screenshots?.path ?? '/icons/filePlaceholder.png'}
                            >
                                <CustomImageHandler
                                    classes="highlighted-post"
                                    thumbnailPath={screenshots.thumbnail}
                                    placeholder={screenshots?.path ?? '/icons/filePlaceholder.png'}
                                    photoPath={screenshots?.path ?? '/icons/filePlaceholder.png'}
                                    altTag={screenshots?.originalName!}/>
                            </div>
                        </Fancybox>
                        <div className='text-center text-primary'>{formatDate(screenshots?.createdAt)}</div>
                    </div>
                </Col>)}
                {isEditable && (
                    <Col xs={12} className="px-md-0 mt-3">
                        <UploadImage numberOfFiles={files?.length} saveFile={setFiles} isDeleteHidden={true}
                                     classes="px-0" isSmall acceptedFormats="image/jpeg, image/png, image/jpg"
                                     isMultiple/>
                        {isBetween(user?.feedItems?.length, 3, 5) &&
                            <p className="text-danger text-end">
                                {t('influencer.profile.maxHighlightedPost')}
                            </p>}
                    </Col>
                )}
            </Row>
        </div>
    );
}
