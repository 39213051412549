import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Campaign} from '../../../../../model/campaign/Campaign';
import {CampaignFilter} from '../../../campaign/component/CampaignFilter';
import {IMyCampaignsFilterProps} from '../../../campaign/dto/IMyCampaigns';
import CardView from './CardView';
import TableView from './TableView';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';

interface Props {
    campaigns: Campaign[];
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
}

const CampaignList = ({campaigns, setFilterParams, filterParams}: Props) => {
    const {t} = useTranslation();
    const [isCardView, setIsCardView] = useState(true);
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);

    return (
        <>
            {selectedInfluencer?.id ?
                <h4>{t('influencer.dashboard.campaigns_from', {firstName: selectedInfluencer?.firstName})}</h4> :
                <h4>{t('influencer.dashboard.yourCampaigns')}</h4>
            }
            <div className="my-1">
                <CampaignFilter searchKey="title" isCardView={isCardView} setIsCardView={setIsCardView}
                                filterParams={filterParams}
                                showCampaignStatus={false}
                                setFilterParams={setFilterParams}/>
            </div>
            {isCardView ? <CardView campaigns={campaigns}/> : <TableView campaigns={campaigns}/>}
        </>
    );
};

export default CampaignList;
