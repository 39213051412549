import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {IReviewsCount, IReviewsData} from '../../../../../model/book/Book';
import EmptyState from '../../../../../shared/components/EmptyState';
import ReviewData from '../../../../brand/campaign/book/offer/modal/tabs/reviews/ReviewData';
import {FeedbackCard} from '../../../../brand/campaign/book/offer/modal/tabs/reviews/FeedbackCard';
import {formatDate} from '../../../../../shared/functions/Functions';

interface IReviewsTabProps {
    state: IReviewsData
}

const ReviewsTab = ({state}: IReviewsTabProps) => {
    return (
        <Row>
            <Col xs={12} className="px-md-0">
                <ReviewData
                    reviewCount={state?.info?.reviewCount as IReviewsCount}
                    reviewNumber={state?.info?.reviewCount?.totalReviews as number}
                    overallScore={state?.info?.reviewSummary?.avgTotalRating as number}
                    communicationScore={state?.info?.reviewSummary?.avgCommunication as number}
                    deliveryScore={state?.info?.reviewSummary?.avgTimeOfDelivery as number}
                    qualityScore={state?.info?.reviewSummary?.avgContentQuality as number}
                />
            </Col>
            <Col xs={12} className="px-md-0">
                {!!state?.reviews?.length ? state?.reviews?.map((review) => (
                    <FeedbackCard
                        key={review?.id}
                        createdAt={formatDate(review?.createdAt)}
                        brandLogo={review?.reviewedBy?.profilePhoto}
                        brandName={review?.reviewedBy?.displayName}
                        campaignName={review?.offer?.campaign?.title}
                        reviewScore={review?.totalRating}
                        hasReply={review?.hasReply}
                        reviewText={review?.comment}
                        reportItems={review?.offer?.reportItems}
                        influencerName={review?.user?.displayName}
                        influencerFeedback={review?.reply}
                        influencerPhoto={review?.user?.profilePhoto?.path as string}
                        influencerComment={formatDate(review?.updatedAt)}
                        isContentAttached={review?.isContentAttached}
                        campaignId={review?.offer?.campaign?.id}
                    />
                )) : <div className="card mt-3">
                    <div className="card-body">
                        <EmptyState/>
                    </div>
                </div>}
            </Col>
        </Row>
    );
};

export default ReviewsTab;
