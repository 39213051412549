import {createSlice} from '@reduxjs/toolkit';
import {IContentModal} from '../../app/brand/campaign/book/offer/dto/IContentModal';

export interface IManageBrandStore {
    selectedInfluencer?: IContentModal | undefined;
    modalInfo?: {
        userId: number,
        offerId: number;
    };
    showBookModal: boolean;
};

const initialManageBrandSlice: IManageBrandStore = {
    selectedInfluencer: undefined,
    modalInfo: undefined,
    showBookModal: false,
};

export const manageBrand = createSlice({
    name: 'manageBrand',
    initialState: initialManageBrandSlice,
    reducers: {
        setSelectedInfluencer: (state, action) => {
            state.selectedInfluencer = action.payload;
            return state;
        },
        setModalInfo: (state, action) => {
            state.modalInfo = action.payload;
            return state;
        },
        setShowBookModal: (state, action) => {
            state.showBookModal = action.payload;
            return state;
        },
    },
});
