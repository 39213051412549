import React, {SetStateAction, useEffect, useState} from 'react';
import {Alert, Button, Col, Row} from 'react-bootstrap';
import EmptyState from '../../../shared/components/EmptyState';
import ExploreHeader from './components/ExploreHeader';
import ExploreCard from './components/ExploreCard';
import ExploreFilterModal from './components/ExploreFilterModal';
import ExploreSort from './components/ExploreSort';
import {InfluencerExploreApi} from './service';
import {InfluencerOfferModal} from '../offer/modal/InfluencerOfferModal';
import {IExploreFilterParams, IExploreItem} from './dto/Explore';
import {Pagination} from '../../../shared/components/Pagination';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import usePagination from '../../../shared/hooks/usePagination';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {BsSliders} from 'react-icons/bs';
import {GiRibbonMedal} from 'react-icons/gi';

interface IGetAllExploreCampaigns {
    exploreFilters: IExploreFilterParams;
    setExploreList: React.Dispatch<SetStateAction<IExploreItem[]>>;
    setTotalPages: React.Dispatch<SetStateAction<number>>;
    history?: any;
    setNbPickedOffers?: React.Dispatch<SetStateAction<number>>;
    setNbReverseOffers?: React.Dispatch<SetStateAction<number>>;
}

interface IStateProps {
    isBookmark: boolean;
    isCharity: boolean;
}

export const getAllExploreCampaigns = ({
                                           exploreFilters,
                                           setExploreList,
                                           setTotalPages,
                                           history,
                                           setNbPickedOffers,
                                           setNbReverseOffers,
                                       }: IGetAllExploreCampaigns) => {
    InfluencerExploreApi.getAllExploreCampaigns(exploreFilters).then(response => {
        setExploreList(response.data);
        setNbPickedOffers && setNbPickedOffers((response as any)?.hasPicked);
        setNbReverseOffers && setNbReverseOffers((response as any)?.reverseCount);
        history.replace({state: {}});
        setTotalPages(Math.ceil(response.count / response.perPage));
    }).catch(error => ErrorToast(error));
};

const InfluencerExplore = () => {
    const history = useHistory();
    const {state} = useLocation();
    const [exploreList, setExploreList] = useState<IExploreItem[]>([]);
    const isBookmarkActive = !!(state as unknown as IStateProps)?.isBookmark;
    const isCharityActive = !!(state as unknown as IStateProps)?.isCharity;

    const {
        setTotalPages,
        totalPages,
        handlePages,
        setFilterParams: setExploreFilters,
        filterParams: exploreFilters,
    } = usePagination({
        perPage: 10, otherKeys: {
            sortBy: 'date', sortType: 'DESC', isFavorite: isBookmarkActive, isCharity: isCharityActive,
        },
    });
    const {t} = useTranslation();
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [nbPickedOffers, setNbPickedOffers] = useState(0);
    const [nbReverseOffers, setNbReverseOffers] = useState(0);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    const [updateExploreList, setUpdateExploreList] = useState(false);
    const handleClose = () => setShowFilterModal(false);
    const handleShow = () => setShowFilterModal(true);
    const handleAddToFavorite = (exploreId: number) => {
        const itemToUpdate = exploreList?.find(exploreItem => exploreItem.id === exploreId);
        InfluencerExploreApi.addToFavorites(exploreId, itemToUpdate?.isFavorite ? 'put' : 'post').then(response => {
            if (response) {
                const isAdd = (response as unknown as IExploreItem[])?.some(explore => explore.id === exploreId);
                SuccessToast(t(isAdd ? 'influencer.explore.addToFavoriteSuccess' : 'influencer.explore.removeFromFavoriteSuccess'));
                getAllExploreCampaigns({
                    exploreFilters,
                    setExploreList,
                    setTotalPages,
                    history,
                    setNbPickedOffers,
                    setNbReverseOffers,
                });
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    };
    useEffect(() => {
        if ((exploreFilters as unknown as IExploreFilterParams)?.sortBy) {
            if (exploreFilters.page > 1 && updateExploreList) {
                setExploreFilters((prev: any) => ({...prev, page: 1}));
                setUpdateExploreList(false);
            }
            getAllExploreCampaigns({
                exploreFilters,
                setExploreList,
                setTotalPages,
                history,
                setNbPickedOffers,
                setNbReverseOffers,
            });
        }
        // eslint-disable-next-line
    }, [exploreFilters, updateExploreList]);

    // function checkActiveFilters() {
    //     if ((exploreFilters as unknown as IExploreFilterParams)?.isPicked) {
    //         return 'warning';
    //     } else if ((exploreFilters as unknown as IExploreFilterParams)?.isFavorite) {
    //         return 'primary';
    //     } else {
    //         return 'outline-secondary';
    //     }
    // }

    return (
        <>
            <ExploreFilterModal showFilterModal={showFilterModal} handleClose={handleClose}
                                nbReverseOffers={nbReverseOffers} exploreFilters={exploreFilters}
                                setExploreFilters={setExploreFilters} nbPickedOffers={nbPickedOffers}/>
            <InfluencerOfferModal show={showOfferModal} setShowModal={setShowOfferModal} campaignId={campaignId}
                                  updateList={setUpdateExploreList}/>
            <div className="row">
                <Col xs={6} className="d-md-none">
                    <Button variant={!showFilterModal ? 'outline-secondary' : 'primary'} onClick={handleShow}>
                        <BsSliders size={20}/>
                    </Button>
                </Col>
                <Col lg={8} className="d-none d-md-flex align-items-center pl-0">
                    <ExploreHeader exploreFilters={exploreFilters} nbPickedOffers={nbPickedOffers}
                                   nbReverseOffers={nbReverseOffers} setExploreFilters={setExploreFilters}/>
                </Col>
                <ExploreSort exploreFilters={exploreFilters}
                             setExploreFilters={setExploreFilters}/>
            </div>
            <Row className="mt-3 mb-md-2">
                {!!nbPickedOffers ? <Col md={7}>
                    <Alert variant="warning" className="box-shadow">
                        <GiRibbonMedal size={20}/>
                        {t('influencer.explore.ivPicked')}
                    </Alert>
                </Col> : ''}
                {!!exploreList?.length ? exploreList?.map((exploreItem) => <div className="col-lg-6 my-2"
                                                                                key={exploreItem?.id}>
                    <ExploreCard setShowModal={setShowOfferModal} exploreItem={exploreItem}
                                 setExploreFilters={setExploreFilters}
                                 handleAddToFavorite={handleAddToFavorite} setCampaignId={setCampaignId}/>
                </div>) : <div className="card">
                    <div className="card-body">
                        <EmptyState/>
                    </div>
                </div>}
                {totalPages <= 1 ? '' :
                    <Col xs={12} className="d-flex justify-content-end pb-lg-0">
                        <Pagination page={exploreFilters?.page} totalPages={totalPages}
                                    handlePagination={handlePages}/>
                    </Col>
                }
            </Row>
        </>
    );
};

export default InfluencerExplore;
