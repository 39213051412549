import {TextField} from '@material-ui/core';
import _debounce from 'lodash/debounce';
import React, {SetStateAction} from 'react';
import {Button, Col, Dropdown, DropdownButton, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsSortDown, BsSortUp} from 'react-icons/bs';
import {FaTh, FaThList} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {IAllStates} from '../../../../store/rootReducer';
import {IMyCampaignsFilterProps} from '../dto/IMyCampaigns';

interface ICampaignFilterProps {
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    setIsCardView?: React.Dispatch<SetStateAction<boolean>>;
    isCardView?: boolean;
    isDeclinedStatus?: boolean;
    showCampaignStatus?: boolean;
    searchKey?: string;
    isAgency?: boolean;
}

export function CampaignFilter({
                                   filterParams,
                                   setFilterParams,
                                   isCardView,
                                   setIsCardView,
                                   searchKey = 'name',
                                   isDeclinedStatus = false,
                                   showCampaignStatus = true,
                                   isAgency,
                               }: ICampaignFilterProps) {
    const {t} = useTranslation();
    const {
        offerStatus,
        OfferActiveStatusEnum,
        OfferNegativeStatusEnum
    } = useSelector((state: IAllStates) => state.enums);
    const changeParamsHandler = (paramKey: string, paramValue: string | number) => {
        setFilterParams(prev => ({...prev, [paramKey]: paramValue !== 'null' ? paramValue : ''}));
    };

    const changeFilterHandler = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFilterParams(prev => ({...prev, [name]: value, page: 1}));
    }, 300);

    const handleSortBy = (by: 'date' | 'publishFrom' | 'publishTo' | 'nbPosts' | '') => {
        setFilterParams(prev => ({...prev, sortBy: by}));
    };

    const handleSortType = (type: 'ASC' | 'DESC') => {
        setFilterParams(prev => ({...prev, sortType: type}));
    };

    return (
        <Row className="justify-content-between">
            <Col md={7} className="pl-0">
                <Row className=" align-items-center">
                    <Col md={7} className="pl-0">
                        <TextField
                            label={`${t('general.button.search')}`}
                            variant="outlined"
                            name={searchKey}
                            onChange={changeFilterHandler}
                            size="small"
                            fullWidth
                        />
                    </Col>
                    {showCampaignStatus && <Col md={5}>
                        <CustomAutocomplete
                            dataArray={isDeclinedStatus ? OfferNegativeStatusEnum : OfferActiveStatusEnum}
                            isWithImage={false}
                            inputLabel="campaign_status"
                            isMultiple={false}
                            disableClearable={false}
                            photoLabel=""
                            queryFilterKey="status"
                            inputLabelTranslation={t('influencer.option.campaign_status')}
                            handleChangeParams={changeParamsHandler}
                        />
                    </Col>}
                </Row>
            </Col>
            <Col md={2}>
                <div className="d-flex align-items-center justify-content-end">
                    {filterParams?.sortType === 'ASC' ?
                        <BsSortDown className="cursor-pointer" onClick={() => handleSortType('DESC')}/> :
                        <BsSortUp className="cursor-pointer" onClick={() => handleSortType('ASC')}/>}
                    <DropdownButton
                        className="mr-2 pl-1 font-weight-normal"
                        variant="transparent"
                        title={`${filterParams?.sortBy ? t(`influencer.explore.${filterParams?.sortBy}`) : t('general.button.sort_by')}`}
                    >
                        {filterParams?.sortBy &&
                            <Dropdown.Item className={``}
                                           onClick={() => handleSortBy('')}>
                                Clear
                            </Dropdown.Item>}
                        <Dropdown.Item className={`text-${filterParams?.sortBy === 'date' ? 'primary' : ''}`}
                                       onClick={() => handleSortBy('date')}>
                            {t('influencer.explore.date')}
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => handleSortBy('nbPosts')}
                                       className={`text-${filterParams?.sortBy === 'nbPosts' ? 'primary' : ''}`}>
                            {t('influencer.explore.nbPosts')}
                        </Dropdown.Item>
                    </DropdownButton>
                    {setIsCardView && <>
                        <Button
                            onClick={() => setIsCardView(true)}
                            variant="icon"
                            className={`mr-2 ${isCardView ? 'active' : ''}`}
                        >
                            <FaTh/>
                        </Button>
                        <Button
                            onClick={() => setIsCardView(false)}
                            variant="icon"
                            className={isCardView ? '' : 'active'}
                        >
                            <FaThList/>
                        </Button>
                    </>}
                </div>
            </Col>

        </Row>
    );
}
