import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Checkbox} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {setLegalCheck} from '../../../../../store/influencer/offerInfluencerSlice';
import { Fancybox } from '../../../../../shared/components/Fancybox';
import { downloadFile, getPptxUrl } from '../../../../../shared/functions/Functions';
import FilePlaceholder from '../../../../../shared/components/placeholder/FilePlaceholder';

export function LegalTab() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {
        campaign,
        isLegalChecked,
        requiredFieldChangeColor
    } = useSelector((state: IAllStates) => state.offerInfluencer);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = event.target;
        dispatch(setLegalCheck(checked));
    };

    return (
        <Row>
            <Col xs={12}>
                <h6 className="text-muted">{t('influencer.offer_management.modal.exclusivity')}</h6>
                <p className="mb-4">
                    {campaign?.exclusivityDescription ?? '-'}
                </p>
            </Col>
            <Col xs={12}>
                <h6 className="text-muted">{t('influencer.offer_management.modal.exc_duration')}</h6>
                <p>{campaign?.exclusivityDuration ? t(`brand.campaign.briefing.step8.${campaign?.exclusivityDuration}`) : '-'}</p>

            </Col>
            <Col xs={12}>
                <h6 className="text-muted">{t('influencer.briefing_detail.competitors')}</h6>
                <p>{campaign?.exclusivityCompetitors?.length ? campaign?.exclusivityCompetitors
                    ?.map((competitor, index) => (
                        <span key={competitor?.id}>
                            {competitor?.description}
                            {(campaign?.exclusivityCompetitors?.length - 1) > index ? ', ' : ''}
                        </span>
                    )) : '-'}
                </p>
            </Col>
            {campaign?.hasByLaws &&
                <>
                    <Col className="mt-4" xs={12}>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.bylaws')}</h6>
                        <p>{campaign?.byLawsExplanation ?? '-'}</p>
                    </Col>
                    <Col xs={12}>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.bylaws_uploads')}</h6>
                    </Col>
                    {!!campaign?.bylaws?.length ? campaign?.bylaws?.map(bylaws => {
                        const isPDF = bylaws.mime === 'application/pdf';
                        return (
                            <div className="col-md-3 my-2 pl-3" key={bylaws?.id}>
                                <Fancybox options={{infinite: false}}>
                                    <div
                                        data-fancybox="bylaws-gallery"
                                        data-type="iframe"
                                        className="cursor-pointer w-100"
                                        data-src={isPDF ? bylaws?.path : getPptxUrl(bylaws?.path)}
                                    >
                                        <FilePlaceholder fileName={bylaws?.name} styleContent={{height: 130}}
                                                        handleDownload={() => downloadFile(bylaws?.path, bylaws?.title)}
                                        />
                                    </div>
                                </Fancybox>
                            </div>
                        )
                    }) : <Col>-</Col>}
                </>
            }
            <Col className="d-flex align-items-center" xs={12}>
                <Checkbox className="mb-0" id="checkbox" onChange={changeHandler} color="primary"
                            checked={isLegalChecked}/>
                <label htmlFor="checkbox"
                        className={`text-left cursor-pointer ${requiredFieldChangeColor ? 'text-danger' : 'text-muted'}`}
                        dangerouslySetInnerHTML={{__html: t('auth.register.brand.step1.terms')}}/>
            </Col>
        </Row>
    );
}
