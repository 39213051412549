import React, {SetStateAction, useState} from 'react';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../../utils/toasters';
import {InfluencerService} from '../../../service';
import ProfileHeader from './ProfileHeader';
import ProfileAbout from './ProfileAbout';
import ProfileCategories from './ProfileCategories';
import ProfileSocial from './ProfileSocial';
import ProfileHighlighted from './ProfileHighlighted';
import {IProfileData} from '../../profile-view';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import {useTranslation} from 'react-i18next';
import TabPanel from '@mui/lab/TabPanel';
import {ICampaignCategories} from '../../../../../model/campaign/CampaignCategory';
import {actions} from '../../../../../store/authentication/authRedux';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IParams} from '../../../../../model/shared/IParams';
import ProfileKpiScreenshots from './ProfileKpiScreenshots';

interface IProfileFormProps {
    userState: IProfileData;
    setState: React.Dispatch<SetStateAction<IProfileData>>;
    setUpdatePlatforms: React.Dispatch<SetStateAction<boolean>>;
}

const ProfileForm = ({userState, setState, setUpdatePlatforms}: IProfileFormProps) => {
    const {t} = useTranslation();
    const [tab, selectTab] = useState('categories');
    const [filesScreenshots, setFilesScreenshots] = useState<Blob[] | MediaSource[]>();
    const isMobile = window.innerWidth <= 600;
    const dispatch = useDispatch();
    const {id} = useParams<IParams>();
    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        selectTab(newValue);
    };
    const onSubmit = async (files: MediaSource[] | Blob[]) => {
        const formData = new FormData();
        if (userState?.user?.categoriesArray?.length !== 3) {
            return WarningToast(t('general.maxThreeCategories'));
        }
        if (files?.length) {
            for (const item of files) {
                formData.append('files', (item as string | Blob));
            }
            await InfluencerService.uploadHighlighted(id, formData).then(response => {
                SuccessToast(t('influencer.profile.successUpdate'));
                setUpdatePlatforms(prev => !prev);
            }).catch(error => ErrorToast(error));
        }

        await InfluencerService.updateInfluencerProfile(id, {
            ...userState.user,
            userCategories: userState?.user?.categoriesArray as unknown as ICampaignCategories[],
        }).then(response => {
            if (response) {
                dispatch(actions.updateComletionInfo(response.completionInfo));
            }
        }).catch(error => ErrorToast(error));

        const formDataScreenshots = new FormData();

        if (filesScreenshots?.length) {
            for (const item of filesScreenshots) {
                formDataScreenshots.append('files', (item as string | Blob));
            }
            await InfluencerService.uploadKpiScreenshots(id, formDataScreenshots).then(response => {
                SuccessToast(t('influencer.profile.successUpdate'));
                setUpdatePlatforms(prev => !prev);
                setFilesScreenshots([]);
            }).catch(error => ErrorToast(error));
        }
    };

    const handleDeleteFile = async (postId: number) => {
        await InfluencerService.deleteHighlighted(postId, id).then(response => {
            if (response) {
                SuccessToast(t('general.successfullyDeletedImage'));
                setUpdatePlatforms(prev => !prev);
            }
        }).catch(error => ErrorToast(error));
    };

    const handleDeleteScreenshot = async (postId: number) => {
        await InfluencerService.deleteKpiScreenshot(postId, id).then(response => {
            if (response) {
                SuccessToast(t('general.successfullyDeletedImage'));
                setUpdatePlatforms(prev => !prev);
            }
        }).catch(error => ErrorToast(error));
    };

    return (
        <>
            <div className="d-none d-md-block">
                <ProfileHeader/>
            </div>
            <ProfileAbout state={userState?.user} setState={setState}/>
            {isMobile &&
                <TabContext value={tab}>
                    <Box>
                        <TabList variant="fullWidth" onChange={handleTab} aria-label="lab API tabs example" centered>
                            <Tab className="text-uppercase"
                                 label={t('influencer.profile.categories')}
                                 value="categories"/>
                            <Tab className="text-uppercase"
                                 label={t('influencer.profile.socialAccounts')}
                                 value="social"/>
                        </TabList>
                        <Box className="mt-3">
                            <TabPanel className="p-0" value="categories">
                                <ProfileCategories state={userState?.user} setState={setState}/>
                            </TabPanel>
                            <TabPanel className="p-0" value="social">
                                <ProfileSocial platforms={userState?.social} setUpdatePlatforms={setUpdatePlatforms}/>
                            </TabPanel>
                        </Box>
                    </Box>
                </TabContext>
            }
            {!isMobile &&
                <ProfileCategories state={userState?.user} setState={setState}/>
            }
            <div className="d-md-none">
                <ProfileHeader/>
            </div>
            {!isMobile &&
                <ProfileSocial platforms={userState?.social} setUpdatePlatforms={setUpdatePlatforms}/>
            }
            <ProfileKpiScreenshots 
                                files={filesScreenshots}
                                setFiles={setFilesScreenshots}
                                user={userState?.user} isEditable handleUploadFiles={onSubmit}
                                handleDeleteFile={handleDeleteScreenshot}/>
            <ProfileHighlighted user={userState?.user} isEditable handleUploadFiles={onSubmit}
                                handleDeleteFile={handleDeleteFile}/>
        </>
    );
};

export default ProfileForm;
