import {User} from '../../../../model/user/User';
import {requests} from '../../../../utils';
import {BRAND_BRIEFING, MANAGE_URL, MESSAGES_URL, PLATFORM_URL} from '../../../../utils/endpoints';
import {IBaseModal} from '../../../shared/user/dto/IBaseModal';
import {iFilterInfluencers, iManageFilters, iManageList} from './calendar/dto/IManageCalendar';
import {iFilterPlatforms} from './calendar/components/CalendarListViewHeader';
import {BaseModel} from '../../../../model/shared/BaseModel';

export const ManageService = {
    getInfluencers: (campaignId: number, params: iFilterInfluencers) =>
        requests.get(`${MANAGE_URL}/influencers/${campaignId}`, params),
    getFilterInfluencers: (campaignId: number, params: iFilterInfluencers) =>
        requests.get(`${MANAGE_URL}/filter/${campaignId}`, params),
    getManages: (campaignId: number, params: iManageFilters): Promise<BaseModel<iManageList[]>> =>
        requests.get(`${MANAGE_URL}/${campaignId}`, params),
    getAdminPlatforms: (params: iFilterPlatforms) =>
        requests.get(`${PLATFORM_URL}/item/all`, params),
    createManageEvent: (campaignId: number, params: iFilterInfluencers | {}) =>
        requests.post(`${MANAGE_URL}/${campaignId}`, params),
    getEventById: (eventId: number) => requests.get(`${MANAGE_URL}/modal/${eventId}`),
    updateFileApprovement: (eventId: number, files: any) => requests.put(`${MANAGE_URL}/file/approve/${eventId}`, {files}),
    createNewComment: (message: any) => requests.post(`${MANAGE_URL}/comment/send`, message),
    createClientComment: (message: any) => requests.post(`${MANAGE_URL}/client/comment/send`, message),
    approveEvent: (eventId: number) => requests.put(`${MANAGE_URL}/approve/${eventId}`, {}),
    updateTrackingLink: (eventId: number, trackingLink: string) => requests.put(`${MANAGE_URL}/trackingLink/${eventId}`, {trackingLink}),
    reschedulePostRequest: (eventId: number, postingDate: string) => requests.put(`${MANAGE_URL}/reschedule/request`, {
        eventId,
        postingDate,
    }),
    updateFeedbackLoop: (campaignId: number) => requests.put(`${BRAND_BRIEFING}/feedback-loop/disable/${campaignId}`, {}),
    getMentionParticipants: (id: number, hasClients?: boolean, withoutSupervisor?: boolean, withInfluencer?: boolean, eventId?: number)
        : Promise<IBaseModal<User[]>> => requests.get(`${MANAGE_URL}/comment/participants/${id}`, {
        hasClients,
        withoutSupervisor,
        withInfluencer,
        eventId,
        page: 1,
        perPage: 0,
    }),
    updateProposalDate: (eventId: number, postingDate: string) => requests.put(`${MANAGE_URL}/proposed/reschedule`, {
        eventId,
        postingDate
    })
};
