import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {User} from '../../../model/user/User';
import {BackButton} from '../../../shared/components/BackButton';
import {Pagination} from '../../../shared/components/Pagination';
import {scrollToTop} from '../../../utils/helpers/ScrollToTop';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {IAgencyDashFilter} from '../influencers/dto/IAgencyDashFilter';
import {AgencyService} from '../service';
import ContentCreatorCard from './components/ContentCreatorCard';
import {AddMoreCreatorsModal} from '../influencers/components/AddMoreCreatorsModal';
import {ConfirmationModal} from '../../../shared/components/alert/ConfirmationModal';
import {setDeselectInfluencer, setUpdateInfluencerList} from '../../../store/agency/agencySlice';
import {useDispatch} from 'react-redux';

const ContentCreators = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [updateList, setUpdate] = useState(false);
    const [showAddCreatorModal, setShowAddCreatorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [filterParams, setFilterParams] = useState<IAgencyDashFilter>({
        page: 1,
        perPage: 8,
        sortCriteria: '',
        sortType: 'DESC',
        displayName: '',
        assignedManagerId: '',
    });
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        AgencyService.getAgencyUsers(filterParams)
            .then(response => {
                setUsers(response.data);
                setTotalPages(Math.ceil(response.count / response.perPage));
            }).catch(err => ErrorToast(err));
    }, [filterParams, updateList]);

    const removeInfluencer = () => {
        AgencyService.removeInfluencer(selectedUserId)
            .then((response) => {
                SuccessToast(t('general.toasts.successfullyDeletedUser'));
                dispatch(setUpdateInfluencerList({}));
                dispatch(setDeselectInfluencer({}));
                setUpdate(prevState => !prevState);
            }).catch((error) => {
            ErrorToast(error);
        });
    };

    const showModalAndSetId = (id: number) => {
        setShowConfirmModal(true);
        setSelectedUserId(id);
    };

    const handlePages = (updatePage: number) => {
        setFilterParams({...filterParams, page: updatePage});
        scrollToTop();
    };
    const activatePendingStatus = () => {
        setFilterParams(prevState => ({...prevState, onlyPending: !prevState.onlyPending, page: 1}));
    };

    return (
        <div className="container">
            <AddMoreCreatorsModal show={showAddCreatorModal}
                                  closeModal={() => setShowAddCreatorModal(false)}
                                  setUpdate={setUpdate}
            />
            <ConfirmationModal title={t('agency.delete_creator_title')}
                               description={t('agency.delete_creator_desc')}
                               show={showConfirmModal}
                               closeModal={() => setShowConfirmModal(false)}
                               action={removeInfluencer}
            />
            <div className="d-flex align-items-center mb-3">
                <BackButton/>
                <h3 className="mb-0">{t('general.button.back')}</h3>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6 pl-0">
                            <h5>{t('brand.account.content_creators')}</h5>
                            <p className="text-muted">
                                {t('brand.account.content_creators_desc')}
                            </p>
                        </div>
                        <div className="col-6 pl-0 text-right">
                            <Button
                                onClick={activatePendingStatus}>{filterParams.onlyPending ? t('brand.package.see_all') : t('general.button.see_pending')}</Button>
                        </div>
                        {users?.map(user => {
                            return (
                                <ContentCreatorCard key={user?.id} user={user} onRemoveClick={showModalAndSetId}/>
                            );
                        })}
                        <div className="col-md-4 pl-0 mb-4 ">
                            <div className="card h-100">
                                <div className="card-body">
                                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                                        <img src="/tempAssets/img-add-agency.png" className="w-fit-content"
                                             alt="Add agency placeholder"/>
                                        <Button variant="primary" className="mt-2"
                                                onClick={() => setShowAddCreatorModal(true)}>
                                            {t('agency.addContentCreator')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {totalPages > 1 &&
                        <div className="d-flex justify-content-end mb-5 pb-2 pb-lg-0 mb-lg-0">
                            <Pagination page={filterParams.page} totalPages={totalPages}
                                        handlePagination={handlePages}/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ContentCreators;
