import {TextField} from '@material-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaEye} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {UserRoles} from '../../../../enum/UserRoles';
import {Contact} from '../../../../model/shared/Contact';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {PasswordVerifySteps} from '../../../../shared/PasswordVerifySteps';
import {IAllStates} from '../../../../store/rootReducer';
import {multipartFormData} from '../../../../utils';
import {AGENCY_USER_URL, PROFILE_CREATE_COMPANY_USER_URL} from '../../../../utils/endpoints';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {ICompanyUser} from '../dto/ICompanyUser';
import {IProfileModal} from '../dto/IProfileModal';

const initialContact: Contact = {
    phone: '',
};
const initialCompanyUser: ICompanyUser = {
    contact: initialContact,
    displayName: '',
    email: '',
    firstName: '',
    isGeneralTermsAccepted: false,
    lastName: '',
    password: '',
    position: '',
    repeatPassword: '',
};

function UserAndRolesModal({show, closeModal, companyUser, updateList}: IProfileModal) {
    const [state, setState] = useState<ICompanyUser>(initialCompanyUser);
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const [passwordShown, setPasswordShown] = useState(false);
    const {t} = useTranslation();
    const {UserPositionEnum} = useSelector((states: IAllStates) => states.enums);
    const {user: {userType}} = useSelector((states: IAllStates) => states.auth);
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);

    useEffect(() => {
        setState(companyUser ?? initialCompanyUser);
    }, [companyUser]);

    function updateAgencyUser() {
        if (companyUser) {
            multipartFormData(files, AGENCY_USER_URL + '/' + companyUser.id, JSON.stringify(state))
                .then(res => {
                    SuccessToast(t('general.toasts.successUpdated'));
                    if (closeModal) {
                        closeModal();
                    }
                    if (updateList) {
                        updateList(prevState => !prevState)
                    }
                }).catch(error => ErrorToast(error));
        } else {
            multipartFormData(files, AGENCY_USER_URL, JSON.stringify(state), 'post')
                .then((res) => {
                    SuccessToast(t('general.toasts.successCreateCompanyUser'));
                    if (closeModal) {
                        closeModal();
                    }
                    if (updateList) {
                        updateList(prevState => !prevState);
                    }
                }).catch(error => ErrorToast(error));
        }
    }

    function updateCompanyUser() {
        if (companyUser) {
            multipartFormData(files, PROFILE_CREATE_COMPANY_USER_URL + '/' + companyUser.id, JSON.stringify(state))
                .then(res => {
                    SuccessToast(t('general.toasts.successUpdated'));
                    if (updateList) {
                        updateList(prevState => !prevState);
                    }
                    closeModalAction();
                }).catch(error => ErrorToast(error));
        } else {
            multipartFormData(files, PROFILE_CREATE_COMPANY_USER_URL, JSON.stringify(state), 'post')
                .then((res) => {
                    SuccessToast(t('general.toasts.successCreateCompanyUser'));
                    if (updateList) {
                        updateList(prevState => !prevState);
                    }
                    closeModalAction();
                }).catch(error => ErrorToast(error));
        }
    }

    function handleChange(evt: any, isContact: boolean = false) {
        const {name, value} = evt.target;
        const valueToSend = value?.trim();
        if (isContact) {
            setState({
                ...state,
                contact: {
                    ...state.contact,
                    [name]: valueToSend,
                },
            });
        } else {
            setState({
                ...state,
                [name]: valueToSend,
            });
        }
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };
    const changePositionHandler = (paramKey: string, paramValue: string | number) => {
        setState((prev: any) => ({
            ...prev,
            position: paramValue,
        }));
    };
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (state.password !== state.repeatPassword) {
            return WarningToast('Passwords don\'t match.');
        }
        isAgency ? updateAgencyUser() : updateCompanyUser();
    };

    const closeModalAction = () => {
        if (closeModal) {
            closeModal();
            setState(initialCompanyUser);
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(`Username: ${state?.email} \nPassword: ${state?.password}`)
    }


    return (
        <>
            <Modal show={show} onHide={closeModalAction} size="lg">
                <form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header>
                        <Modal.Title>
                            {companyUser?.id ?
                                <h4 className="m-0">{t('brand.account.users_and_roles_modal.edit_user')}</h4>
                                :
                                <h4 className="m-0">{t('brand.account.users_and_roles_modal.create_user')}</h4>
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg="4" className="px-0">
                                <UploadImage saveFile={setFiles}
                                             isDeleteHidden={true}
                                             isMultiple={false}
                                             isPreviewArray={false}
                                             previewImage={companyUser?.profilePhoto?.path ?? '/icon/img-missImg.png'}
                                />
                            </Col>
                            <Col lg="8" className="mt-4 mt-lg-0">
                                <Form.Group>
                                    <TextField
                                        onChange={handleChange}
                                        defaultValue={companyUser?.firstName}
                                        fullWidth
                                        required
                                        label={t('general.first_name')}
                                        variant="outlined"
                                        size="small"
                                        name="firstName"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <TextField
                                        onChange={handleChange}
                                        defaultValue={companyUser?.lastName}
                                        fullWidth
                                        required
                                        label={t('general.last_name')}
                                        variant="outlined"
                                        size="small"
                                        name="lastName"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <CustomAutocomplete dataArray={UserPositionEnum ?? []}
                                                        defaultArray={companyUser?.position ?? undefined}
                                                        isWithImage={false}
                                                        inputLabel="position"
                                                        isMultiple={false}
                                                        photoLabel=""
                                                        isRequired={true}
                                                        queryFilterKey=""
                                                        inputLabelTranslation={t('brand.account.users_and_roles_modal.position')}
                                                        handleChangeParams={changePositionHandler}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-0 mt-lg-4">
                            <Col lg={6}>
                                <Form.Group className="mb-4">
                                    <TextField
                                        onChange={handleChange}
                                        defaultValue={companyUser?.email}
                                        fullWidth
                                        required
                                        label={t('general.email')}
                                        variant="outlined"
                                        size="small"
                                        name="email"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-4">
                                    <TextField
                                        onChange={event => handleChange(event, true)}
                                        defaultValue={companyUser?.contact.phone}
                                        fullWidth
                                        required
                                        label={t('general.phone_number')}
                                        variant="outlined"
                                        size="small"
                                        name="phone"
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-4">
                                    <TextField
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start" className="cursor-pointer"
                                                                onClick={togglePassword}>
                                                    <FaEye color="gray"></FaEye>
                                                </InputAdornment>
                                            ),
                                        }}
                                        defaultValue={companyUser?.password}
                                        fullWidth
                                        required={!companyUser?.id}
                                        label={t('general.new_password')}
                                        variant="outlined"
                                        type={passwordShown ? 'text' : 'password'}
                                        size="small"
                                        name="password"
                                    />
                                </Form.Group>
                                {!!state?.password?.length && (
                                    <Col className={`mb-4 px-0`}>
                                        <PasswordVerifySteps password={state?.password ?? ''}/>
                                    </Col>
                                )}
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="mb-4">
                                    <TextField
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start" className="cursor-pointer"
                                                                onClick={togglePassword}>
                                                    <FaEye color="gray"></FaEye>
                                                </InputAdornment>
                                            ),
                                        }}
                                        defaultValue={companyUser?.repeatPassword}
                                        fullWidth
                                        required={!companyUser?.id}
                                        label={t('general.repeat_password')}
                                        variant="outlined"
                                        type={passwordShown ? 'text' : 'password'}
                                        size="small"
                                        name="repeatPassword"
                                    />
                                </Form.Group>
                            </Col>
                            {!state?.id && <Col xs={12}>
                                <Alert variant={'danger'}>
                                    {t('brand.account.users_and_roles_modal.pass_disclaimer')}
                                </Alert>
                            </Col>}
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeModal}>
                            {t('general.button.close')}
                        </Button>
                        {!state?.id && //show only on create
                            <Button onClick={handleCopy} variant="primary" className="btn-sm">
                                {t('general.button.copyCredentials')}
                            </Button>}
                        <Button type="submit" variant="primary" className="btn-sm">
                            {t('general.button.save')}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default UserAndRolesModal;
