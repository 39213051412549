import {InputAdornment} from '@mui/material';
import React, {SetStateAction, useRef, useState} from 'react';
import {Button, Card, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Platforms} from '../../../model/platform/Platforms';
import {TextField} from '@material-ui/core';
import {SocialService} from '../service';
import {IAuthAutomatic} from '../dto/IAuthAutomatical';
import {ErrorToast, SuccessToast, WarningToast} from '../../../utils/toasters';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {ConfirmationModal} from '../../../shared/components/alert/ConfirmationModal';
import {formatDate} from '../../../shared/functions/Functions';

interface ISocialPlatformCardProps {
    openManualAuthModal: (platform?: Platforms) => void;
    platform?: Platforms;
    setUpdatePlatforms: React.Dispatch<SetStateAction<boolean>>;
}

export function AuthSocialPlatformCard({openManualAuthModal, platform, setUpdatePlatforms}: ISocialPlatformCardProps) {
    const [isAuthorizeLoading, setIsAuthorizeLoading] = useState(false);
    const {t} = useTranslation();
    const [handle, setHandle] = useState('');
    const influencerData = useSelector((state: IAllStates) => state.influencerRegistration.user);
    const {user} = useSelector((state: IAllStates) => state.auth);
    const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
    const [showDisconnectConfirmModal, setShowDisconnectConfirmModal] = useState(false);
    const [showSupportConfirmModal, setShowSupportConfirmModal] = useState(false);
    const isApproved = platform?.status === 'approved';
    const hasApi = platform?.hasApi;
    const selectedInfluencerId = localStorage.getItem('selectedAgencyCreator');

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, dimension?: string) => {
        const { value} = event.target;
        setHandle(value.charAt(0) === '@' ? value?.substring(1).replace(/\s/g, '') : value.replace(/\s/g, ''))
    };
        const authAutomaticRequest = () => {
        setIsAuthorizeLoading(true);
        SocialService.socialAuthorize({
            username: handle,
            platformId: platform?.id,
            userId: selectedInfluencerId ? selectedInfluencerId : (user ? user.id : influencerData?.id),
        } as IAuthAutomatic)
            .then((response) => {
                setUpdatePlatforms(prevState => !prevState);
                if (response?.isEnabled === false && response?.status === 'pending') {
                    WarningToast(t('auth.register.influencer.manual_auth.social_being_verified'));
                } else {
                    SuccessToast(t('general.toasts.successAuthorize'));
                }
            }).finally(() => setIsAuthorizeLoading(false))
            .catch(err => ErrorToast(err));
    };
    const disconnectAuthRequest = () => {
        setIsAuthorizeLoading(true);
        SocialService.disconnectSocial(platform?.id, selectedInfluencerId ? +selectedInfluencerId : (user ? user.id : influencerData?.id))
            .then(() => {
                SuccessToast(t('general.toasts.successDisconnect'));
                setUpdatePlatforms(prevState => !prevState);
            }).finally(() => setIsAuthorizeLoading(false))
            .catch(err => ErrorToast(err));
    };

    const reAuthorizeRequest = () => {
        setIsAuthorizeLoading(true);
        SocialService.reAuthorizePlatform(selectedInfluencerId ? +selectedInfluencerId : user.id, platform?.id ?? 0)
            .then(() => {
                SuccessToast(t('general.toasts.successUpdatedAudienceData'));
                setUpdatePlatforms(prevState => !prevState);
            }).finally(() => setIsAuthorizeLoading(false))
            .catch(err => ErrorToast(err));
    };

    const openSupportPage = () => {
        window.open('https://calendly.com/influencevision-support/support-influence-vision');
    };

    return (
        <>
            <ConfirmationModal title={t('general.need_help')}
                               show={showSupportConfirmModal}
                               description={t('general.contact_support')}
                               closeModal={() => setShowSupportConfirmModal(false)}
                               saveBtn="contact"
                               action={openSupportPage}/>
            <ConfirmationModal title={t('auth.register.influencer.manual_auth.disconnect_handle')}
                               show={showDisconnectConfirmModal}
                               description=""
                               closeModal={() => setShowDisconnectConfirmModal(false)}
                               action={disconnectAuthRequest}/>
            <ConfirmationModal title={t('auth.register.influencer.manual_auth.confirm_handle')}
                               description={`@${handle}`}
                               show={showAddConfirmModal}
                               closeModal={() => setShowAddConfirmModal(false)}
                               action={authAutomaticRequest}/>
            <Card>
                <Card.Body>
                    <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                <img src={platform?.path} alt={platform?.name}/>
                                <div className="d-flex flex-column ml-1">
                                    <span className="font-weight-semi-bold">{platform?.name}</span>
                                    <span
                                        className="text-muted">{platform?.username ? '@' + platform?.username : 'n.a.'}</span>
                                </div>
                            </div>
                            {isApproved && platform?.audienceUpdate && <div className="d-flex flex-column">
                                <span className="font-weight-semi-bold">Last update:</span>
                                <span className="text-muted">{formatDate(platform?.audienceUpdate) ?? ''}</span>
                            </div>}
                            {isApproved &&
                                <div className="d-flex justify-content-end">
                                    <img src="/icons/check.png" alt="check icon"/>
                                </div>
                            }
                            {isAuthorizeLoading &&
                                <Spinner animation="border" className="mr-2 text-primary" role="status" size="sm"/>}

                        </div>
                        {(!hasApi && !platform?.username) &&
                            <div className="d-flex justify-content-end mt-2">
                                <Button variant="primary" onClick={() => openManualAuthModal(platform)}>
                                    {t('general.button.manualAddition')}
                                </Button>
                            </div>
                        }
                        {platform?.status === 'pending' &&
                            <div className="d-flex justify-content-end mt-2">
                            <span className="text-warning">
                        {t('auth.register.influencer.manual_auth.social_being_verified')}
                            </span>
                            </div>
                        }
                        {platform?.status === 'rejected' &&
                            <div className="d-flex justify-content-end mt-2">
                            <span className="text-warning">
                              {t('auth.register.influencer.manual_auth.try_manual_auth')}
                            </span>
                            </div>
                        }
                        {(isApproved || (!platform?.hasOffers && platform?.status === 'pending')) &&
                            <div className="row mt-2">
                                {platform.hasOffers && <div className="col-12 d-flex pl-md-0">
                      <span className="text-danger">
                      {t('general.ongoingOffersError')}
                    </span>
                                </div>}
                                {(isApproved && user?.id) &&
                                    <div className="col-6 px-md-0">
                                        <Button size="sm" onClick={() => openManualAuthModal(platform)} className="mt-2"
                                                variant="outline-primary">
                                            {t('general.button.manual_update')}
                                        </Button>
                                    </div>
                                }
                                <div className={`col-6 d-flex justify-content-end align-items-end px-md-0`}>
                                    <Button
                                        size="sm"
                                        disabled={isAuthorizeLoading}
                                        variant="outline-danger"
                                        onClick={() => platform.hasOffers ? setShowSupportConfirmModal(true) : setShowDisconnectConfirmModal(true)}>
                                        {t('general.button.disconnect')}
                                    </Button>
                                </div>
                            </div>
                        }
                        {(isApproved && hasApi && platform?.canUpdateData && !platform?.manualUserInput) &&
                            <Button onClick={reAuthorizeRequest} className="mt-2" variant="outline-primary">
                                {t('general.button.automatic_update')}
                            </Button>
                        }
                        {(platform?.status === 'rejected') &&
                            <div className="row mt-2">
                                {platform.hasOffers && <div className="col-6 d-flex pl-md-0">
                      <span className="text-danger font-10">
                      {t('general.ongoingOffersError')}
                    </span>
                                </div>}
                                <div
                                    className={`col-${platform.hasOffers ? 6 : 12} d-flex justify-content-end align-items-end pr-md-0`}>
                                    <Button
                                        disabled={isAuthorizeLoading || platform.hasOffers}
                                        variant="outline-danger"
                                        onClick={() => openManualAuthModal(platform)}>
                                        {t('auth.register.influencer.manual_auth.manual_auth')}
                                    </Button>
                                </div>
                            </div>

                        }
                        {(hasApi && !platform?.username) &&
                            <div className="d-flex justify-content-end justify-content-between mt-2">
                                <TextField
                                    label={t('auth.register.influencer.manual_auth.handle')}
                                    name="username"
                                    variant="outlined"
                                    size="small"
                                    value={handle}
                                    onChange={changeHandler}
                                    className="w-100 "
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment
                                                sx={{
                                                    marginRight: 0
                                                }}
                                                position="start"
                                            >
                                                @
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Button disabled={isAuthorizeLoading} onClick={() => setShowAddConfirmModal(true)}
                                        variant="primary ml-2">
                                    {t('general.button.add')}
                                </Button>
                            </div>
                        }
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
