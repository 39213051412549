import React from 'react';
import {Route, Switch} from 'react-router';
import ProfileSettings from './user/components/ProfileSettings';
import UserAndRoles from './user/components/UserAndRoles';
import InfluencerProfileEdit from '../influencer/profile/profile-form';
import {MyNetwork} from './user/components/MyNetwork';

export function UserRoutes() {
    return (
        <>
            <Switch>
                <Route path="/settings/users" component={UserAndRoles}/>
                <Route path="/settings/edit/:id" component={InfluencerProfileEdit}/>
                <Route path="/settings/my-network" component={MyNetwork}/>
                <Route path="/settings" component={ProfileSettings}/>
            </Switch>
        </>
    );
}
