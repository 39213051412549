import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IOfferWithCampaign, IProfileData } from '..';
import { formatDate } from '../../../../../shared/functions/Functions';
import { RatingStar } from '../../../../../shared/components/RatingStar';
import BookingOfferModal from '../../../../brand/campaign/book/offer/modal';
import { useParams } from 'react-router';
import { IParams } from '../../../../../model/shared/IParams';
import CommentModal from '../../../../../shared/components/CommentModal';
import { ErrorToast, WarningToast } from '../../../../../utils/toasters';
import { UserService } from '../../../../shared/user/service';
import { IAllStates } from '../../../../../store/rootReducer';
import { useSelector } from 'react-redux';
import { CustomImageHandler } from '../../../../../shared/components/CustomImageHandler';

interface ICooperationItem  {
  cooperation: IOfferWithCampaign;
  setIdOffer: (...args: any) => void;
  setTriggerUpdateComment: (...args: any) => void;
  setProfileDataNew?: (...args: any) => void;
  profileDataNew?: IProfileData;
  setProfileData?: (...args: any) => void;
}

export function CooperationItem({
  cooperation,
  setIdOffer,
  setTriggerUpdateComment,
  setProfileDataNew,
  profileDataNew,
  setProfileData,
}: ICooperationItem) {
  const { t } = useTranslation();
  const { id } = useParams<IParams>();
  const review = cooperation?.reviews[0];
  const [offerModal, setOfferModal] = useState({
    show: false,
    offerId: 0,
    userId: 0,
    campaignId: 0,
    status: '',
  });
  const [commentModal, setCommentModal] = useState({
    show: false,
  });
  const [newMessage, setNewMessage] = useState<string>('');
  const [isSendDisabled, setIsSendDisabled] = useState(false);

  const {bundle} = useSelector((state: IAllStates) => state.offerInfluencer);

  const sendMessageHandler = async () => {
    if (newMessage === '') {
        return WarningToast(t('general.toasts.warnEmptyMessage'));
    }
    setIsSendDisabled(true);
    await UserService.putPrivateComment(cooperation?.id, {message: newMessage.replaceAll('\n', '</br>')})
        .then(response => {
          setNewMessage('');
          setTriggerUpdateComment((prev: any) => !prev);
          const updatedOffers = profileDataNew && profileDataNew.offers?.map((prev) => {
            if (prev.id === cooperation?.id) {
              return {...prev, privateCommentsCount: prev.privateCommentsCount + 1};
            }
            return prev;
          });
          setProfileDataNew?.((prev: IProfileData) => ({...prev, offers: updatedOffers}));
        }).catch(err => ErrorToast(err)).finally(() => setIsSendDisabled(false));
  };

  useEffect(() => {
    if (!commentModal.show) {
      setProfileData?.(profileDataNew);
    }
  }, [commentModal.show]);

  return (
    <>
      <BookingOfferModal
        show={offerModal?.show}
        closeModal={() => {
          setOfferModal((prev) => ({ ...prev, show: false }));
        }}
        offerId={offerModal?.offerId}
        userId={+id}
        modalStatus={offerModal?.status}
        passedCampaignId={offerModal?.campaignId}
      />
      <CommentModal
        {...commentModal}
        closeModal={setCommentModal}
        sendMessageHandler={sendMessageHandler}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        isSendDisabled={isSendDisabled}
        bundle={bundle}
        setIdOffer={setIdOffer}
        canMention={false}
      />

      <Row className="border bg-white mb-3 rounded overflow-hidden flex-column flex-md-row">
        <div>
          <CustomImageHandler
              classes="d-none d-md-block h-100"
              style={{
              aspectRatio: '1 / 1',
              objectFit: 'cover',
              position: 'relative',
              maxWidth: '7rem',
            }}
            thumbnailPath={cooperation?.campaign?.campaignImages[0]?.thumbnail}
            photoPath={cooperation?.campaign?.campaignImages[0].thumbnail as string}
            altTag={cooperation?.campaign?.campaignImages[0].name}/>

          <CustomImageHandler
            classes="d-md-none"
            style={{
                aspectRatio: '1 / 1',
                objectFit: 'cover',
                position: 'relative',
                maxHeight: '120px',
                width: '100%',
              }}
            thumbnailPath={cooperation?.campaign?.campaignImages[0]?.thumbnail}
            photoPath={cooperation?.campaign?.campaignImages[0].thumbnail as string}
            altTag={cooperation?.campaign?.campaignImages[0].name}/>
        </div>
        <Row className="py-md-2 py-2 px-0 align-items-center col" style={{ gap: '6px' }}>
          <div className="col-md-3 col-auto">
            <h5 className="word-break">{cooperation?.campaign?.title}</h5>
          </div>

          <div className="col text-center">
            <div className="text-primary font-weight-bold">
              {review?.createdAt ? formatDate(review?.createdAt) : 'n.a.'}
            </div>
            <div>Review date</div>
          </div>
            {!cooperation.isClient && <div className="col text-center">
            <div className="font-weight-bold text-primary euro-symbol">
              {cooperation?.campaign?.isTransparentPricing
                ? cooperation?.totalWithFee
                : cooperation?.price}
            </div>
            <div>{t('brand.campaign.briefing.budget')}</div>
          </div>}
          <div className="col text-center">
            <div className="font-weight-bold w-fit-content mx-auto">
              {review?.totalRating ? (
                <RatingStar rating={+review?.totalRating} />
              ) : (
                'n.a.'
              )}
            </div>
            <div>{t('influencer.profile.reviewRating')}</div>
          </div>
          <div className="col text-center">
            <div className="font-weight-bold d-flex justify-content-center align-items-center ">
              {cooperation?.privateCommentsCount ?? 0}
              <img src={`/icons/comments.svg`} alt="comments-icon" className="ml-1"/>
            </div>
            <div>{t('brand.campaign.dashboard.measure.comments')}</div>
          </div>

          <div className="col-12 col-md">
            <Button className="btn-outline-primary mb-2 w-100" onClick={() => {
                setCommentModal((prev) => ({...prev, show: true}));
                setIdOffer(() => cooperation?.id);
            }}>
              {t('general.button.comment')}
            </Button>
              {!cooperation.isClient &&
              <Button
              className="w-100"
              onClick={() => {
                setOfferModal({
                  show: true,
                  offerId: cooperation?.id,
                  userId: cooperation?.id,
                  campaignId: cooperation.campaign?.id,
                  status:
                    cooperation.buttonType === 'Resume' ? 'negotiate' : '',
                });
              }}
            >
              {t('general.button.view_offer')}
            </Button>}
          </div>
        </Row>
      </Row>
    </>
  );
}
