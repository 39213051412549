import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {StarIcon} from '../../../../shared/Icons';
import {ButtonUpgradePro} from './ButtonUpgradePro';

export function UserAndRolesBasicPack() {
    const {t} = useTranslation();
    return (
        <Card>
            <Card.Body className="text-center">
                <StarIcon width="100" height="100"/>
                <h5 className="mt-4">{t('brand.package.availableInPro')}</h5>
                <p className="my-4 text-muted">{t('brand.package.availableInProDesc')}</p>
                <ButtonUpgradePro isOnlyUpgrade={true}/>
            </Card.Body>
        </Card>
    );
};
