import {TextField} from '@material-ui/core';
import {Tabs} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, {SetStateAction, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiCopy} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import {AccessRightEnum} from '../../../../../../../enum/AccessRightEnum';
import {CustomImageHandler} from '../../../../../../../shared/components/CustomImageHandler';
import {Fancybox} from '../../../../../../../shared/components/Fancybox';
import {MessageBody} from '../../../../../../../shared/components/message/MessageBody';
import ToggleSwitch from '../../../../../../../shared/components/ToggleSwitch';
import {
    downloadFile,
    formatDate,
    getCorrectImageThumbnail,
    getExternalLink,
    hasAccessRights
} from '../../../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../../../../utils/toasters';
import {ManageService} from '../../../service';
import {iManageList} from '../../dto/IManageCalendar';
import {statusesToHideApproveButton} from './CalendarUpdateModal';
import {a11yProps, TabPanel} from '../../../../../../../shared/components/MaterialTabsComponents';
import FilePlaceholder from '../../../../../../../shared/components/placeholder/FilePlaceholder';

interface IProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setCurrTab: React.Dispatch<SetStateAction<number>>;
    currTab: number;
    handleCopy: (val: string) => void;
    lastMessageRef: React.RefObject<HTMLInputElement>;
}

const CalendarManageRightCard = ({state, handleCopy, setState, lastMessageRef, setCurrTab, currTab}: IProps) => {
    const {user} = useSelector((states: IAllStates) => states.auth);
    const isCaptionHidden = !['instagram_story'].includes(state?.offerItem?.socialPlatform?.name);
    const {t} = useTranslation();
    const showInfluencerFeedback = !hasAccessRights(AccessRightEnum.INFLUENCER_FEEDBACK_HIDE);
    const showClientComments = hasAccessRights(AccessRightEnum.MANAGE_CAN_VIEW_CLIENT_COMMENTS);
    const handleChangeTab = (event: React.SyntheticEvent<Element, Event>, value: number) => {
        setCurrTab(value);
        setTimeout(() => {
            lastMessageRef?.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }, 0);
    };
    const handleSwitch = (attachmentId: number, isApproved: boolean) => {
        const copy = state;
        const indexToReplace = copy?.attachments?.findIndex(attachment => attachment?.id === attachmentId);
        copy.attachments[indexToReplace] = {
            ...copy.attachments[indexToReplace],
            isApproved: !isApproved,
        };
        ManageService.updateFileApprovement(state?.id, copy?.attachments?.map(attachment => ({
            id: attachment?.id,
            isApproved: attachment?.isApproved,
        }))).then(response => {
            setState(response);
            SuccessToast(t('general.toasts.successChangeApprovement'));
        }).catch(error => ErrorToast(error));
    };
    return (
        <>
            <div className="my-3">
                <h6>
                    Storyline
                </h6>
                <TextField label="Storyline"
                           variant="outlined"
                           multiline
                           InputProps={{
                               endAdornment: (
                                   <BiCopy className="copy-icon pr-1"
                                           onClick={() => handleCopy(state?.storyline ?? '')}/>
                               ),
                           }}
                           className="w-100"
                           size="small"
                           value={state?.storyline ?? ''}/>
            </div>
            {isCaptionHidden && <>
                <div className="d-flex align-items-center mt-3 mb-2">
                    <h6 className="mb-0">
                        {t('influencer.planner.caption')} -
                    </h6>
                    <div className="text-muted font-weight-normal ml-1">
                        {t('influencer.planner.copyDescription')}
                    </div>
                </div>
                {<div className="d-flex align-items-start">
                    <TextField label="Caption"
                               variant="outlined"
                               multiline
                               InputProps={{
                                   endAdornment: (
                                       <BiCopy className="copy-icon pr-1"
                                               onClick={() => handleCopy(state?.caption ?? '')}/>
                                   ),
                               }}
                               className="w-100"
                               size="small"
                               value={state?.caption ?? ''}/>
                </div> ?? (
                    <span className="text-muted">
                        [{t('brand.campaign.manage.modal.insertTag')}]
                    </span>
                )}
            </>}

            <h6 className="mb-0 mt-3">
                {t('influencer.planner.contentFiles')}
            </h6>
            <Row>
                {!!state?.attachments?.length ? state?.attachments?.map((attachment, index) => {
                    const isPDF = attachment?.type === 'application/pdf';
                    return (
                        <Col md="6" className={`my-2 ${index % 2 !== 0 ? 'pr-0 pl-md-2 pl-0' : 'pr-md-2 pr-0 pl-0'}`}
                            key={attachment?.id}>
                            <Card className="h-100">
                                <Card.Body className="d-flex align-items-center flex-row">
                                    {!!attachment?.externalLink ? (
                                        <a href={getExternalLink(attachment?.externalLink)} target='_blank' className="cursor-pointer">
                                            <CustomImageHandler
                                                photoPath={getCorrectImageThumbnail(!!attachment?.externalLink, attachment?.originalName ?? '', attachment?.thumbPath ?? '', attachment?.path)}
                                                altTag="Event"
                                                thumbnailPath={attachment?.thumbnail}
                                                placeholder={getCorrectImageThumbnail(!!attachment?.externalLink, attachment?.originalName ?? '', attachment?.thumbPath ?? '', attachment?.path)}
                                                style={{width: 85, aspectRatio: '1/1'}}
                                                classes="default-radius img-cover"
                                            />
                                        </a>
                                    ) : (
                                        <Fancybox options={{infinite: false}}>
                                            <div
                                                data-fancybox="gallery"
                                                className="cursor-pointer"
                                                data-src={attachment?.externalLink ?? attachment?.path ?? '/icons/filePlaceholder.png'}
                                            >
                                                {isPDF ? (
                                                    <FilePlaceholder fileName={attachment?.name} classContent='default-radius' styleContent={{height: '85px', width: '85px'}}/>
                                                ) : (
                                                    <CustomImageHandler
                                                        photoPath={getCorrectImageThumbnail(!!attachment?.externalLink, attachment?.originalName ?? '', attachment?.thumbPath ?? '', attachment?.path)}
                                                        altTag="Event"
                                                        thumbnailPath={attachment?.thumbnail}
                                                        placeholder={getCorrectImageThumbnail(!!attachment?.externalLink, attachment?.originalName ?? '', attachment?.thumbPath ?? '', attachment?.path)}
                                                        style={{width: 85, aspectRatio: '1/1'}}
                                                        classes="default-radius img-cover"
                                                    />
                                                )}
                                            </div>
                                        </Fancybox>
                                    )}
                                    <div className="ml-3 w-100">
                                        <a target="_blank" className="text-dark font-weight-semi-bold word-break"
                                           href={getExternalLink(attachment?.externalLink) ?? attachment?.path ?? '/icons/filePlaceholder.png'}
                                           rel="noreferrer">
                                            {t('general.file')} {index + 1}/{state?.attachments?.length} - <span
                                            className="text-muted">{state?.id}</span>
                                        </a>
                                        <p className="text-muted mb-0">{formatDate(attachment?.createdAt)}</p>
                                        {!!attachment?.externalLink ? (
                                            <a
                                                href={getExternalLink(attachment?.externalLink)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('general.externalLink')}
                                            </a>
                                        ) : (
                                            <span className="cursor-pointer text-primary"
                                                onClick={() => downloadFile(attachment?.externalLink ?? attachment?.path ?? '/icons/filePlaceholder.png', attachment?.originalName)}>
                                                    {t('general.download')}
                                            </span>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="font-weight-semi-bold mb-0">Approve</p>
                                            <ToggleSwitch
                                                onChange={() => handleSwitch(attachment?.id, attachment?.isApproved)}
                                                small={true}
                                                key={attachment?.id}
                                                disabled={statusesToHideApproveButton?.includes(state?.status)}
                                                id={`Attachment - ${attachment?.id}`}
                                                checked={attachment?.isApproved}
                                            />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                ) : <p>{t('influencer.planner.no_files_uploaded')}</p>}
            </Row>
            <Box className="d-flex flex-column" sx={{height: '400px'}}>
                <Box className="mb-3">
                    <Tabs defaultValue={currTab} value={currTab} onChange={handleChangeTab}
                          aria-label="basic tabs example"
                          TabIndicatorProps={{style: {background: 'var(--primary)'}}}>
                        <Tab label="Influencer Feedback"
                             className={`${!showInfluencerFeedback && 'd-none'}`} {...a11yProps(0)} />
                        <Tab label="Management" className={`${!showClientComments && 'd-none'}`}{...a11yProps(1)} />
                    </Tabs>
                </Box>
                <div className="overflow-auto pr-2 w-100 h-100">
                    <TabPanel value={currTab} index={0}>
                        {!!state?.comments?.length ? state?.comments?.map((comment) => (
                            <MessageBody comment={comment as any} key={comment?.id} haveFiles={false}
                                         senderData={comment?.sentBy}
                                         isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                        )) : <p>{t('influencer.planner.noComments')}</p>}
                    </TabPanel>
                    <TabPanel value={currTab} index={1}>
                        {!!state?.clientComments?.length ? state?.clientComments?.map((comment) => (
                            <MessageBody comment={comment as any} key={comment?.createdAt + comment.id}
                                         haveFiles={false}
                                         senderData={comment?.sentBy}
                                         isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                        )) : <p>{t('influencer.planner.noComments')}</p>}
                    </TabPanel>
                    <div ref={lastMessageRef}/>
                </div>
            </Box>
        </>
    );
};

export default CalendarManageRightCard;
