import React, {useState} from 'react';
import {Button, Col, ProgressBar, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {MdClose} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ProfileCard from '../../../../app/shared/user/components/ProfileCard';
import {IProfile} from '../../../../app/shared/user/components/ProfileSettings';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {UserRoles} from '../../../../enum/UserRoles';
import {actions} from '../../../../store/authentication/authRedux';
import {IAllStates} from '../../../../store/rootReducer';
import {CustomImageHandler} from '../../../components/CustomImageHandler';
import {SupportTutorialIcon} from '../../../components/SupportTutorialIcon';
import {SupportWidget} from '../../../SupportWidget';
import SupportModal from './SupportModal';
import {setSelectetInfluencer} from '../../../../store/agency/agencySlice';

interface IProfileProps {
    toggle: () => void;
}

const ProfileMenu = ({toggle}: IProfileProps) => {
    const [isSupportOpened, setIsSupportOpened] = useState(false);
    const {
      user,
      user: { completionInfo, agencyCompany, company },
    } = useSelector((state: IAllStates) => state.auth);
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const history = useHistory();
    const logout = () => {
        dispatch(actions.logout());
        localStorage.clear();
        dispatch(setSelectetInfluencer(undefined));
        history.push('/auth/login');
    };

    const profileModals: IProfile[] = [
        {
            componentKey: 'personal-info',
            image: '/icons/profile/id-card.png',
            isFilled: completionInfo?.personalInfo,
            title: `${t('brand.account.personal_info')}`,
            description: `${t('brand.account.personal_info_desc')}`,
            role: ['brand', 'influencer', 'brand_master', 'agency', 'agency_master'],
        },
        {
            componentKey: 'company-info',
            image: '/icons/profile/business-and-trade.png',
            isFilled: completionInfo?.companyInfo,
            title: `${t('brand.account.company_info')}`,
            description: `${t('brand.account.company_info_desc')}`,
            role: ['brand_master', 'agency_master'],
        },
        {
            componentKey: 'billing-information',
            image: '/icons/profile/online-shopping.png',
            isFilled: completionInfo?.billingInfo,
            title: `${t('brand.account.billing_information')}`,
            description: `${t('brand.account.billing_information_desc')}`,
            role: ['brand_master', 'influencer', 'agency_master'],
        },
        {
            componentKey: 'iv-profile',
            image: '/icons/profile/user.png',
            isFilled: completionInfo?.profileInfo,
            title: `${t('brand.account.ivProfile')}`,
            description: `${t('brand.account.ivProfile_desc')}`,
            role: ['influencer'],
        },
        {
            componentKey: 'invoices',
            image: '/icons/profile/invoice.svg',
            isFilled: undefined,
            title: `${t('influencer.profile.invoices')}`,
            description: `${t('influencer.profile.invoices_desc')}`,
            role: ['influencer', 'agency', 'agency_master'],
        },
        {
            componentKey: 'users-and-roles',
            image: '/icons/profile/user-role.png',
            isFilled: undefined,
            title: `${t('brand.account.users_and_roles')}`,
            description: `${t('brand.account.users_and_roles_desc')}`,
            role: ['brand_master', 'agency_master'],
        },
        {
            componentKey: 'subscription-plan',
            image: '/icons/profile/presentation.png',
            isFilled: undefined,
            title: `${t('brand.account.subscription_plan')}`,
            description: t(`brand.package.${company?.package?.name}`).toUpperCase() + ` (Fee ${Math.round((1 - Number(company?.fee ?? 0.0)) * 100)}%)`,
            role: ['brand', 'brand_master'],
        },
        {
            componentKey: 'content-creators',
            image: '/icons/profile/user-role.png',
            isFilled: undefined,
            title: `${t('brand.account.content_creators')}`,
            description: `${t('brand.account.content_creators_desc')}`,
            role: ['agency', 'agency_master'],
        },

    ];

    const shouldRemoveBilling = (componentKey: string) => {
        return (user.userType === 'influencer' && agencyCompany?.id && componentKey === 'billing-information');
    };
    return (
        <div className="profile-card py-5 py-md-3">
            <div>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <p className="messages">{t('brand.account.profile_menu')}</p>
                    </div>
                    <div onClick={toggle} className="icons-bg">
                        <MdClose className="icon"/>
                    </div>
                </div>
                <div className="d-flex border-bottom pb-3 my-3">
                    <CustomImageHandler
                        altTag="User Profile"
                        classes="profile-img mr-3"
                        photoPath={user.profilePhoto?.path as string}
                    />

                    <div className="d-flex flex-column justify-content-between">
                        <div>
                            <h5>
                                {user.firstName} {user.lastName}
                            </h5>
                            <p>{[UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(user.userType as UserRoles)
                                ? user?.agencyCompany?.displayName : user.company?.displayName}</p>
                        </div>
                        <Button
                            onClick={() => {
                                history.push(InternalRoutes.BRAND_PROFILE);
                                toggle();
                            }}
                            size="sm"
                            variant="outline-primary"
                        >
                            {t('brand.account.account_settings')}
                        </Button>
                    </div>
                </div>
                <Row>
                    {profileModals.map(
                        (item) =>
                            item.role?.includes(user.userType) && !shouldRemoveBilling(item.componentKey) && (
                                <Col xs={6} className="mb-3 card-profile" key={item.componentKey}>
                                    <ProfileCard
                                        {...item}
                                        userType={user.userType}
                                        toggleHandler={toggle}
                                        currentUserType={user.userType}
                                        openFromSideMenu={true}
                                    />
                                </Col>
                            ),
                    )}
                </Row>
                <div className="mt-2">
                    <div className="d-flex justify-content-between font-14">
                        <p className="font-weight-semi-bold">
                            {t('brand.account.profile_completion')}
                        </p>
                        <p className="font-weight-semi-bold">
                            {completionInfo?.percentage}%
                        </p>
                    </div>
                    <ProgressBar
                        className="progress-md"
                        now={completionInfo?.percentage}
                    />
                </div>
                {/* {paymentRoles.includes(user?.userType) && <>
                    <hr className="sidebar-hr"/>
                    <h6 className="mt-2 text-muted text-center">
                        Subscription package
                    </h6>
                    <h3 className="text-center text-uppercase">
                        {t(`brand.package.${company?.package?.name}`)}
                    </h3>
                    {featureKeys?.map((feature) => {
                        return (
                            <div className="d-flex justify-content-between align-items-center" key={feature}>
                                    <span className="font-weight-semi-bold">
                                        {t(`brand.account.${feature}`)}
                                    </span>
                                <TooltipPackage description="noFeatureInBasic"
                                                trigger="click"
                                                show={!company?.package?.[feature as keyof IPackage]}>
                                    <Checkbox checked={!!company?.package?.[feature as keyof IPackage]} size="small"
                                              color="primary"/>
                                </TooltipPackage>
                            </div>
                        )
                    })}

                    <div className="text-center" onClick={() => toggle()}>
                        <ButtonUpgradePro variant="outline-primary"
                                          customText={company?.package?.name !== PackagesEnum.BASIC ? 'moreDetails' : undefined}/>
                    </div>

                </>} */}
                <Row className="d-md-none">
                    <Col className="my-3" xs={12}>
                        <div className="d-flex align-items-center">
                            <SupportTutorialIcon action={() => setIsSupportOpened(true)} isForMobile={true}/>
                            <h6 className="mb-0 ml-3 text-uppercase">{t('general.button.help/tutorials')}</h6>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className="d-flex align-items-center">
                            <SupportWidget isForMobile={true}/>
                            <h6 className="mb-0 ml-3 text-uppercase">{t('general.button.contact/support')}</h6>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="last-row mb-4">
                {/*<Col >*/}
                {/*    <div className="notification">*/}
                {/*        <img src={'/tempAssets/img-ringing.png'} alt="User profile"/>*/}
                {/*        <div className="align-items-center">*/}
                {/*            <p>{t('general.notifications')}</p>*/}
                {/*            <p>3 {t('brand.account.updates')}</p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                <Col className="p-0 d-flex align-items-center pb-3 justify-content-center">
                    <Button size="sm" variant="outline-secondary" className="text-uppercase col-6" onClick={logout}>
                        {t('general.button.sign_out')}
                    </Button>
                </Col>
            </Row>
            <SupportModal show={isSupportOpened} closeModal={() => setIsSupportOpened(false)}/>
        </div>
    );
};

export default ProfileMenu;
