import React, {SetStateAction, useState} from 'react';
import {Button, Col} from 'react-bootstrap';
import {TiWarningOutline} from 'react-icons/ti';
import {useDispatch, useSelector} from 'react-redux';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import CustomDatePicker from '../../../../../shared/components/DatePicker';
import {formatDate, getCorrectFilterTitle, removeTimeZoneFromDate} from '../../../../../shared/functions/Functions';
import {CalendarSwitcher} from '../../../../../shared/components/CalendarSwitcher';
import {FaCalendarAlt, FaPencilAlt} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';
import {plannerInfluencer} from '../../../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../../../store/rootReducer';
import {iManageList} from '../../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {plannedStatuses} from '../../../reporting/components/ReportingCampaignCard';
import {ISelectedYearMonth} from '../../index';
import {InfluencerPlannerService} from '../../service';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';

interface IPreviewHeaderProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    selectedYearMonth: ISelectedYearMonth;

}

const PreviewHeader = ({state, setState, selectedYearMonth}: IPreviewHeaderProps) => {
    const [rescheduleDate, setRescheduleDate] = useState(state?.reschedule?.postingDate ?? state?.postingDate);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {offerItem: {offer}} = state;
    const {filterParams} = useSelector((reduxState: IAllStates) => reduxState.plannerInfluencer);
    const [isRescheduleClicked, setIsRescheduleClicked] = useState(false);
    const changeStep = async (isIncrease: boolean) => {
        const stepToGo = isIncrease ? state?.nextId : state?.previousId;
        await InfluencerPlannerService.getPlannerModalData(stepToGo as unknown as string).then(response => {
            setState(response);
        }).catch(error => ErrorToast(error));
    };

    const handleChangeTimepicker = (newValue: string | undefined | null, name: string) => {
        setRescheduleDate(newValue ?? '');
    };

    const handleChangePostingDate = async () => {
        await InfluencerPlannerService.rescheduleEvent({
            postingDate: rescheduleDate,
            eventId: state?.id,
        }).then(response => {
            setState(prev => ({
                ...prev,
                reschedule: undefined, postingDate: rescheduleDate
            }));
            if (response) {
                InfluencerPlannerService.getEvents({
                    ...filterParams,
                    currentYear: selectedYearMonth?.currentYear,
                    currentMonth: +selectedYearMonth?.currentMonth,
                } as any).then(res => {
                    dispatch(plannerInfluencer.actions.setEvents(res.data));
                    SuccessToast(t('general.toasts.successRescheduled'));
                    setIsRescheduleClicked(false);
                }).catch(error => ErrorToast(error));
            }
        }).catch(error => ErrorToast(error));
    };

    const hasWarning = plannedStatuses.includes(state?.status);
    return (
        <>
            <div className="col-12 border-bottom mb-3">
                <div className="d-md-flex align-items-center justify-content-between w-100 pb-2 ">
                    <div className="d-flex align-items-center">
                        <CustomImageHandler
                            altTag="Influencer logo"
                            classes="rounded w-80px h-80px img-cover"
                            thumbnailPath={offer?.campaign?.campaignImages?.[0]?.thumbnail as string}
                            photoPath={offer?.campaign?.campaignImages?.[0]?.path as string}
                        />

                        <div className="ml-3">
                            <h6 className="mb-0">
                                {offer?.campaign?.title ?? '-'}
                            </h6>
                            <div className="d-flex justify-content-between align-items-center mt-1 flex-wrap"
                                 style={{'gap': '8px'}}>
                                <div className={`status mr-1 ${state?.status} w-fit-content`}>
                                    {getCorrectFilterTitle(state?.status, t)}
                                </div>
                                <CalendarSwitcher increaseStep={() => changeStep(true)}
                                                  reduceStep={() => changeStep(false)}
                                                  isReduceDisabled={!state?.previousId}
                                                  isIncreaseDisabled={!state?.nextId}
                                                  classes={{'holder': 'm-0 d-md-none', 'btn-l': 'ml-0'}}
                                />
                            </div>
                        </div>
                    </div>
                    <CalendarSwitcher increaseStep={() => changeStep(true)} reduceStep={() => changeStep(false)}
                                      isReduceDisabled={!state?.previousId}
                                      isIncreaseDisabled={!state?.nextId}
                                      classes={{'holder': 'd-none d-md-block'}}
                    />
                </div>
            </div>
            <Col xs={12} md={6} xl={hasWarning ? 3 : 4} className="mb-2 mb-xl-0">
                <div className="card h-100">
                    <div className="card-body">
                                <span className="font-weight-semi-bold">
                                    {t('influencer.planner.postingType')}
                                </span>
                        <div>
                            <img src={state?.offerItem?.socialPlatform?.path}
                                 alt={state?.offerItem?.socialPlatform?.name}/>
                            <span className="ml-2">
                                 {t(`general.platforms.${state?.offerItem?.socialPlatform?.name}`)}
                            </span>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6} xl={hasWarning ? 3 : 4} className="mb-2 mb-xl-0">
                <div className="h-100">
                    <div className="card h-100">
                        <div className="card-body d-flex justify-content-center flex-column">
                            <div className="d-flex flex-column">
                                <span className="font-weight-semi-bold">
                                    {t('influencer.planner.postingDate')}
                                </span>
                                <div className="position-relative w-fit-content">
                                    <FaCalendarAlt
                                        className="text-danger font-20"/>
                                    <div
                                        className="position-absolute bg-white d-flex align-items-center justify-content-center border"
                                        style={{
                                            width: 15,
                                            height: 15,
                                            top: '35%',
                                            left: '35%',
                                            borderRadius: '50%',
                                            boxShadow: '1px 1px 2px #00000026',
                                        }}
                                    >
                                        <FaPencilAlt className="font-12 text-danger"/>
                                    </div>
                                    <span className="position-absolute ml-2">{formatDate(state?.postingDate)}</span>
                                </div>
                            </div>
                            <div className="position-absolute" style={{right: 10}}>
                                {!isRescheduleClicked ?
                                    <div>
                                        <Button variant="primary" onClick={() => setIsRescheduleClicked(true)}
                                                className="w-fit position-relative">
                                            {t('general.button.reschedule')}
                                        </Button>
                                        {state?.reschedule &&
                                            <span style={{right: 0}} className="notification-bullet-sm"/>
                                        }
                                    </div> :
                                    <div className="d-flex align-items-end ">
                                        <CustomDatePicker
                                            disableFrom={removeTimeZoneFromDate(state?.offerItem?.offer?.campaign?.publishFrom ?? '')}
                                            disableTo={removeTimeZoneFromDate(state?.offerItem?.offer?.campaign?.publishTo ?? '')}
                                            value={rescheduleDate}
                                            onChange={(e) => handleChangeTimepicker(e as unknown as string, 'postingDate')}
                                            label={t('general.button.reschedule')}/>
                                        <Button variant="primary" onClick={handleChangePostingDate}
                                                className="w-fit ml-1">
                                            {t('general.button.save')}
                                        </Button>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6} xl={hasWarning ? 3 : 4} className="mb-2 mb-xl-0">
                <div className="h-100">
                    <div className="card h-100">
                        <div className="card-body">
                                    <span className="font-weight-semi-bold">
                                        {t('influencer.planner.feedbackLoopEndDate')}
                                    </span>
                            <div className="position-relative w-fit-content">
                                <FaCalendarAlt
                                    className="text-primary font-20"/>
                                <div
                                    className="position-absolute bg-white d-flex align-items-center justify-content-center border"
                                    style={{
                                        width: 15,
                                        height: 15,
                                        top: '35%',
                                        left: '35%',
                                        borderRadius: '50%',
                                        boxShadow: '1px 1px 2px #00000026',
                                    }}
                                >
                                    <FaPencilAlt className="font-12 text-primary"/>
                                </div>
                                <span
                                    className="position-absolute ml-2">
                                    {state?.feedBackLoopDate ? formatDate(state?.feedBackLoopDate) : '-'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            {hasWarning && <Col md={6} xs={12} xl={3}>
                <div className="card h-100">
                    <div className="card-body">
                        <TiWarningOutline size={25} className="text-danger"/>
                        <span className="ml-2 font-weight-semi-bold">
                                {t('general.waitingForApprovalModal')}
                        </span>
                    </div>
                </div>
            </Col>}
        </>
    );
};

export default PreviewHeader;
