import {TextField} from '@material-ui/core';
import _debounce from 'lodash/debounce';
import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {FaSearch} from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch, useSelector} from 'react-redux';
import {User} from '../../../model/user/User';
import {Pagination} from '../../../shared/components/Pagination';
import {useAgencyCreators} from '../../../shared/hooks/useAgencyCreators';
import {setSelectetInfluencer} from '../../../store/agency/agencySlice';
import {IAllStates} from '../../../store/rootReducer';
import EmptyState from '../../../shared/components/EmptyState';
import {useTranslation} from 'react-i18next';

interface IFindAgencyCreatorsProps {
    closeModal?: () => void;
    renderPagination?: boolean;
};

export function FindAgencyCreators({closeModal, renderPagination = false}: IFindAgencyCreatorsProps) {
    const dispatch = useDispatch();
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const {
        users,
        totalPages,
        filterParams,
        count,
        setFilterParams,
    } = useAgencyCreators();
    const {t} = useTranslation();
    const deselectInfluencer = () => {
        dispatch(setSelectetInfluencer(undefined));
        if (closeModal) {
            closeModal();
        }
    };
    const selectInfluencer = (user: Partial<User>) => {
        if (selectedInfluencer?.id !== user.id) {
            dispatch(setSelectetInfluencer(user));
            if (closeModal) {
                closeModal();
            }
        }
    };

    const handleChange = _debounce((event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target;
        setFilterParams(prevState => ({...prevState, [name]: value}));
    }, 300);
    const loadMoreData = () => {
        const valueToUpdate = filterParams?.perPage + 10;
        setFilterParams((prev: any) => ({...prev, perPage: valueToUpdate}));
    };
    return (
        <>
            <Row>
                <Col xs={12} className="p-1 mb-3">
                    <div className="d-flex align-items-center justify-content-center mb-3 my-2">
                        <TextField
                            name="displayName"
                            label="Content creator search"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            className="w-md-50"
                        />
                        <div style={{marginLeft: '-1.5rem', zIndex: 1}} className="cursor-pointer"
                             onClick={() => {
                             }}>
                            <FaSearch className="text-muted" size={16}/>
                        </div>
                    </div>
                </Col>
                <Col onClick={deselectInfluencer} xs={6} className="mb-3 cursor-pointer d-md-none text-center">
                    <img
                        src={'/icons/img-all-users.png'}
                        className="rounded-circle img-object-fit-none shadow"
                        width="100"
                        height="100"
                        alt="User photo"
                        onError={(e: any) => (
                            // eslint-disable-next-line
                            (e.target.onerror = null),
                                (e.target.src = '/icons/UserPlaceholder.svg')
                        )}
                    />
                    <h6 className="mb-0 mt-2 text-center">See all creators</h6>
                </Col>
            </Row>
            {users?.length ?
                <div className="w-100">
                    <InfiniteScroll
                        dataLength={users?.length || 0}
                        className="row"
                        next={() => {
                            loadMoreData();
                        }}
                        endMessage={
                            !renderPagination && <div className="col-12">
                                <p className="text-center">
                                    <b>
                                        You have seen all users!
                                    </b>
                                </p>
                            </div>
                        }
                        hasMore={renderPagination ? false : (users?.length || 0) < Number(count)}
                        loader={!renderPagination && <div className="col-12">
                            <h4>Loading...</h4>
                        </div>
                        }
                    >

                        {users?.map((item, i: number)=> {
                            const isSelected = item.id === selectedInfluencer?.id
                            return (
                                <Col onClick={() => selectInfluencer(item)} xs={6} lg={3}
                                     className="mb-3 cursor-pointer" key={`${item.displayName}-${i}`}>
                                    <div
                                        className={`d-flex flex-column align-items-center ${isSelected ? 'selected-user' : ''}`}>
                                        <img
                                            src={item?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.svg'}
                                            className={`${isSelected ? 'selected-agency-photo' : ''} rounded-circle img-cover`}
                                            width="100"
                                            height="100"
                                            alt="User photo"
                                            onError={(e: any) => (
                                                // eslint-disable-next-line
                                                (e.target.onerror = null),
                                                    (e.target.src = '/icons/UserPlaceholder.svg')
                                            )}
                                        />
                                        {isSelected && <p className="mb-0 active-badge"
                                                          style={{bottom: item.hasRedDot ? 40 : 20}}>Active</p>}
                                        <h6 className="mb-0 mt-2">
                                            {!!item.displayName ? item.displayName : (item.firstName + ' ' + item.lastName)}
                                        </h6>
                                        {item?.hasRedDot &&
                                            <div className="d-flex align-items-center">
                                                <div className="red-dot mr-1"></div>
                                                <p className="text-muted mb-0">Action needed</p>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            )
                        })}
                    </InfiniteScroll>
                    {(renderPagination && totalPages > 1) &&
                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-end">
                                <Pagination page={filterParams?.page} totalPages={totalPages}
                                            handlePagination={(newPage) => {
                                                setFilterParams(prev => ({...prev, page: newPage}))
                                            }}/>
                            </div>
                        </div>
                    }
                </div>
                :
                <EmptyState desc={t('agency.user_empty')}
                            btnText={t('agency.add_more_creators')}
                />

            }
        </>
    );
}
