import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {plannerAgency} from '../../../store/agency/plannerSlice';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import planner, {ISelectedYearMonth} from '../../influencer/planner';
import {
    IPlannerData,
    IPlannerEventsQuery,
    IPlannerOffers,
    IRescheduleNotify
} from '../../influencer/planner/dto/IPlanner';
import AgencyCalendarWrapper from './components/AgencyCalendarWrapper';
import AgencyPlannerCampaignCard from './components/AgencyPlannerCampaignCard';
import {AgencyPlannerService} from './service';


interface ILocation {
    campaignId: number;
    publishFrom?: string;
}

export function AgencyPlanner() {
    const {filterParams} = useSelector((state: IAllStates) => state.plannerAgency);
    const {state} = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const [isToDoSelected, setIsToDoSelected] = useState(false);
    const [rescheduleNotify, setRescheduleNotify] = useState<IRescheduleNotify[]>([]);
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false)
    const [selectedYearMonth, setSelectedYearMonth] = useState<ISelectedYearMonth>({
        currentYear: moment(new Date()).format('Y'),
        currentMonth: moment(new Date()).format('M'),
    });
    const [selectedCalendarView, setSelectedCalendarView] = useState<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>('listWeek');
    const [offers, setOffers] = useState<IPlannerOffers>();

    useEffect(() => {
        AgencyPlannerService.getPlannerOffers({
            ...filterParams,
            // influencerId: selectedInfluencer?.id,
            campaignId: (state as ILocation)?.campaignId ?? filterParams?.campaignId
        }).then(response => {
            setOffers(
                {
                    data: response?.data,
                    info: response?.info,
                    totalPlanned: response?.totalPlanned,
                },
            );
            dispatch(plannerAgency.actions.setCampaigns(response.data?.map((campaign: IPlannerData) => (
                campaign.campaign
            ))));
            if ((state as ILocation)?.campaignId) {
                dispatch(plannerAgency.actions.setFilterParams({campaignId: (state as ILocation)?.campaignId}));
                history.replace({});
                dispatch(plannerAgency.actions.setSelectedCampaign(
                    response?.data?.find((campaign: IPlannerData) => campaign.campaign.id === (state as ILocation)?.campaignId)?.campaign
                ))
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [(filterParams as unknown as IPlannerEventsQuery)?.campaignId, selectedInfluencer?.id]);

    useEffect(() => {
        if ((state as ILocation)?.publishFrom) {
            history.replace({});
            setSelectedYearMonth({
                currentYear: moment((state as ILocation)?.publishFrom).format('Y'),
                currentMonth: moment((state as ILocation)?.publishFrom).format('M'),
            })
        }
    }, [(state as ILocation)?.publishFrom])
    useEffect(() => {
        AgencyPlannerService.getRescheduleNotify()
            .then((response) => {
                setRescheduleNotify(response);
            });
    }, [filterParams?.updateCount]);
    useEffect(() => {
        AgencyPlannerService.getEvents({
            ...filterParams,
            influencerId: selectedInfluencer?.id,
            currentYear: selectedYearMonth?.currentYear,
            currentMonth: +selectedYearMonth?.currentMonth,
        } as any).then(response => {
            dispatch(plannerAgency.actions.setEvents(response.data));
        }).catch(err => ErrorToast(err))
    }, [filterParams, JSON.stringify(selectedYearMonth), selectedCalendarView, selectedInfluencer?.id]);
    return (
        <>
            <Row>
                <Col md={6} lg={6} xl={9}>
                    <AgencyCalendarWrapper selectedCalendarView={selectedCalendarView}
                                           setSelectedYearMonth={setSelectedYearMonth}
                                           selectedYearMonth={selectedYearMonth}
                                           isToDoSelected={isToDoSelected}
                                           setIsToDoSelected={setIsToDoSelected}
                                           setIsCreateModalOpened={setIsCreateModalOpened}
                                           isCreateModalOpened={isCreateModalOpened}
                                           setSelectedCalendarView={setSelectedCalendarView}/>
                </Col>
                <Col md={6} lg={6} xl={3}>
                    <AgencyPlannerCampaignCard rescheduleNotify={rescheduleNotify} offers={offers}/>
                </Col>
            </Row>
        </>
    );
};