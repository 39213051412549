import React, {useState} from 'react';
import {Card, Image} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {UserRoles} from '../../../../enum/UserRoles';
import {IAllStates} from '../../../../store/rootReducer';
import BillingInfoModal from './BillingInfoModal';
import {CompanyInfoModal} from './CompanyInfoModal';
import GlobalPreferencesModal from './GlobalPreferencesModal';
import LoginAndSecurityModal from './LoginAndSecutiryModal';
import PersonalInfoModal from './PersonalInfoModal';
import PriceListModal from './PriceListModal';
import {IProfile} from './ProfileSettings';

export default function ProfileCard({
                                        image, toggleHandler,
                                        isFilled,
                                        title,
                                        description,
                                        componentKey,
                                        currentUserType,
                                        userType,
                                        openFromSideMenu
                                    }: IProfile) {
    const [show, setShow] = useState(false);
    const {user: {id, agencyCompany}} = useSelector((state: IAllStates) => state.auth);
    const hasAgency = !!agencyCompany?.id
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const history = useHistory();
    const chooseModal = () => {
        switch (componentKey) {
            case 'personal-info':
                return <PersonalInfoModal show={show} closeModal={handleClose} userType={currentUserType} id={id}/>;
            case 'billing-information':
                return <BillingInfoModal show={show} closeModal={handleClose} userType={currentUserType}/>;
            case 'login-and-security':
                return <LoginAndSecurityModal show={show} closeModal={handleClose}/>;
            case 'global-preferences':
                return <GlobalPreferencesModal show={show} closeModal={handleClose}/>;
            case 'price-list':
                return <PriceListModal key={id} show={show} closeModal={handleClose} id={id}/>;
            case 'company-info':
                return <CompanyInfoModal show={show} closeModal={handleClose}/>;
        }
    };

    function checkPages() {
        if (toggleHandler) {
            toggleHandler();
        }
        switch (componentKey) {
            case 'users-and-roles':
                return history.push(InternalRoutes.BRAND_USER);
            case 'social-accounts':
                return history.push(InternalRoutes.BRAND_SOCIAL);
            case 'iv-profile':
                return history.push(`/influencer/profile/${id}`);
            case 'iv-profile-edit':
                return history.push(`/settings/edit/${id}`);
            case 'invoices':
                return history.push(`/${currentUserType === 'agency_master' ? 'agency' : currentUserType }/invoices`);
            case 'subscription-plan':
                return history.push('/brand/package-overview');
            case 'contracts':
                    return history.push('/brand/contracts');
            case 'content-creators':
                return history.push('/agency/content-creators');
            case 'my-network':
                return history.push(InternalRoutes.MY_NETWORK);
            case 'clients':
                return history.push(InternalRoutes.BRAND_CLIENT);
            case 'suppliers':
                return history.push(InternalRoutes.BRAND_SUPPLIER);
            default:
                return null;

        }
    }

    const showSidebarDescription = (key: string) => {
        const keysToShow = ['subscription-plan'];
        return keysToShow.includes(key)
    }

    return (
        <> {openFromSideMenu ?
            <div onClick={() => {
                handleShow();
                checkPages();
            }}
                 className={userType === UserRoles.INFLUENCER ? 'px-2 px-md-0' : ''}
            >
                <Card>
                    <img src={image}
                         alt="Icon placeholder"/>
                    <div className="d-flex flex-column mx-2">
                        <span>{title}</span>
                        {(isFilled === undefined || (hasAgency && componentKey === 'billing-information')) ? '' :
                            <Image className="is-completed absolute-img"
                                   src={isFilled ? '/icons/profile/tick-mark.png' : '/icons/profile/exclamation-mark.png'}
                                   alt="Icon placeholder"/>
                        }
                        {showSidebarDescription(componentKey) && <span className="text-muted">
                        {description}
                    </span>}
                    </div>
                </Card>
            </div>
            :
            <div className={`card account-card ${(isFilled || isFilled === undefined) ? '' : 'border-danger'}`}
                 onClick={() => {
                     handleShow();
                     checkPages();
                 }}>
                <div className="d-flex justify-content-between align-items-start mb-3">
                    <img src={image} alt="Icon placeholder"/>
                    {isFilled === undefined ? '' :
                        <img src={isFilled ? '/icons/profile/tick-mark.png' : '/icons/profile/exclamation-mark.png'}
                             alt="Icon placeholder"/>
                    }
                </div>
                <h5>{title}</h5>
                <div className="text-muted">{description}</div>
            </div>
        }
            {chooseModal()}
        </>
    );
}
