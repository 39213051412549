import React, {SetStateAction} from 'react';
import {Col, Row} from 'react-bootstrap';
import {Campaign} from '../../../model/campaign/Campaign';
import {TermsOfServiceAlert} from '../../../shared/components/alert/TermsOfServiceAlert';
import {Pagination} from '../../../shared/components/Pagination';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import CampaignList from './components/desktop/CampaignList';
import ExploreOffersCard from './components/desktop/ExploreOffersCard';
import PlannerReports from './components/desktop/PlannerReportsCard';
import SupportDashboardCard from './components/desktop/SupportDashboardCard';
import {IMyOffers} from './dto/IDashboard';
import CreatorSuccessCard from '../../agency/influencers/components/CreatorSuccessCard';


interface Props {
    campaigns: Campaign[];
    myOffers: IMyOffers;
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    totalPages: number;
    handlePages: (updatePage: number) => void;
    isAgency?: boolean;
}

const DesktopDashboard = ({
                              campaigns,
                              myOffers,
                              setFilterParams,
                              filterParams,
                              handlePages,
                              totalPages,
                              isAgency,
                          }: Props) => {
    return (
        <div className="d-none d-md-block">
            <Row>
                <TermsOfServiceAlert/>
                <Col xl={5} className="d-flex">
                    <ExploreOffersCard myOffers={myOffers as IMyOffers}/>
                </Col>
                <Col xl={5} className="mt-3 mt-xl-0 d-flex">
                    <PlannerReports myOffers={myOffers as IMyOffers} filterParams={filterParams}
                                    setFilterParams={setFilterParams}/>
                </Col>
                <Col xl={2} className="mt-3 mt-xl-0">
                    {isAgency ?
                        <CreatorSuccessCard isOnDashboard={true}/> :
                        <SupportDashboardCard/>
                    }
                </Col>

                <Col xs={12} className="mt-4 pr-0">
                    <CampaignList campaigns={campaigns} filterParams={filterParams}
                                  setFilterParams={setFilterParams}/>
                </Col>
            </Row>
            {totalPages > 1 && (
                <Pagination
                    page={filterParams.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            )}
        </div>
    );
};

export default DesktopDashboard;
