import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OptionComments from "../../app/brand/campaign/options/components/OptionComments";
import { IBundle } from "../../model/options/IBundle";
import { useDispatch } from "react-redux";
import { offerInfluencer } from "../../store/influencer/offerInfluencerSlice";
import { useTranslation } from "react-i18next";

interface ICommentModal {
  show: boolean;
  closeModal: (...args: any) => void;
  sendMessageHandler: (...args: any) => void;
  setNewMessage: (...args: any) => void;
  setIdOffer?: (...args: any) => void;
  newMessage: string;
  isSendDisabled: boolean;
  bundle: IBundle | undefined;
  canMention?: boolean;
}

export default function CommentModal({ 
  show, 
  closeModal, 
  sendMessageHandler, 
  newMessage, 
  setNewMessage, 
  isSendDisabled,
  bundle,
  setIdOffer,
  canMention,
}: ICommentModal) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const lastMessageRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (bundle?.comments?.length && lastMessageRef?.current) {
        lastMessageRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
        });
    }
}, [bundle?.comments?.length, lastMessageRef?.current]);

  return (
    <Modal
      show={show}
      onHide={() => {
        dispatch(offerInfluencer.actions.setBundle({comments: []}));
        closeModal((prev: any) => ({...prev, show: false}));
        setIdOffer?.(0);
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-0">
        <OptionComments
          bundle={bundle as IBundle}
          sendMessageHandler={sendMessageHandler}
          lastMessageRef={lastMessageRef}
          styleCard="shadow-none border-0"
          newMessage={newMessage}
          setNewMessage={setNewMessage}
          isSendDisabled={isSendDisabled}
          canMention={canMention}
        />
      </Modal.Body>
      <Modal.Footer className="pt-0">
        <Button 
              className="btn-outline-primary" 
              onClick={() => {
                dispatch(offerInfluencer.actions.setBundle({comments: []}));
                closeModal((prev: any) => ({...prev, show: false}));
                setIdOffer?.(setIdOffer);
              }}>
          {t('general.button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
