import {requests} from '../../../utils';
import {BaseModel} from '../../../model/shared/BaseModel';
import {BRAND_CLIENT} from '../../../utils/endpoints';
import {Company} from '../../../model/user/Company';
import {User} from "../../../model/user/User";

export interface IClientParams {
  type: 'client' | 'supplier';
  page: number;
  perPage: number;
  displayName?: string;
  campaignId?: string;
  excludeCompanies?: number[];
}

export const ClientService = {
  getClientsOrSuppliers: (params: IClientParams): Promise<BaseModel<Company[]>> => requests.get(`${BRAND_CLIENT}/partners`, params),
  getCurrentTransferCompany: (id: number): Promise<Company> => requests.get(`${BRAND_CLIENT}/transfer/${id}`),
  transferCampaign: (campaignId: number, companyId: number) => requests.put(`${BRAND_CLIENT}/transfer/${campaignId}/${companyId}`, {}),
  assignMultipleClients: (campaignId: number, body: User[]) => requests.put(`${BRAND_CLIENT}/assign/multiple/campaign/${campaignId}`, {users: body}),
  getAssignedClients: (campaignId: number): Promise<Company[]> => requests.get(`${BRAND_CLIENT}/share/modal/${campaignId}`),
}
