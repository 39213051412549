import {TextField} from '@material-ui/core';
import React, {SetStateAction, useState} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaEdit} from 'react-icons/fa';
import {FiInfo} from 'react-icons/fi';
import {BiCopy} from 'react-icons/bi';
import {FaCalendarAlt} from 'react-icons/fa';
import {formatDate, gePlatformIcon, removeTimeZoneFromDate} from '../../../../../../../shared/functions/Functions';
import {ErrorToast, SuccessToast} from '../../../../../../../utils/toasters';
import {ManageService} from '../../../service';
import {iManageList} from '../../dto/IManageCalendar';
import CustomDatePicker from '../../../../../../../shared/components/DatePicker';

interface IProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    handleCopy: (val: string) => void;
}

export const statusesToEditTrackinglink = ['proposed', 'scheduled', 'approved', 'feedback_loop', 'reported'];
const CalendarManageLeftCard = ({state, setState, handleCopy}: IProps) => {
    const [isRescheduleClicked, setIsRescheduleClicked] = useState(false);
    const {t} = useTranslation();
    const updateTracklink = (eventId: number) => {
        ManageService.updateTrackingLink(eventId, state?.trackingLink).then(response => {
            if (response) {
                SuccessToast(t('general.toasts.successUpdated'));
            }
        }).catch(error => ErrorToast(error));
    };

    const reschedulePostRequest = () => {
        ManageService.reschedulePostRequest(state?.id, state?.postingDate).then(response => {
            if (response) {
                SuccessToast(t('general.toasts.successRescheduled'));
                setIsRescheduleClicked(false);
                setState(prev => ({...prev,
                    reschedule: response?.reschedule,
                    postingDate: response?.postingDate,
                    }));
            }
        }).catch(error => ErrorToast(error));
    };

    const handleChangeTimepicker = (newValue: string | undefined | null, name: string) => {
        setState(prev => ({...prev, [name]: newValue}));
    };

    return (
        <div>
            <h6 className="text-muted">Placement ID: #{state?.id}</h6>
            <Card className="my-2">
                <Card.Body className="p-3">
                    {gePlatformIcon({
                        iconPath: state?.offerItem?.socialPlatform?.path,
                        platformName: t(`general.platforms.${state?.offerItem?.socialPlatform?.name}`)
                    })}
                    <div className="mt-2">{t('brand.campaign.manage.postingType')}</div>
                </Card.Body>
            </Card>
            <Card className="my-2">
                <Card.Body className="p-3">
                    <div className="d-flex align-items-center text-primary justify-content-between">
                        {isRescheduleClicked ?
                            <div className="d-flex align-items-end ">
                                <CustomDatePicker
                                    disableFrom={removeTimeZoneFromDate(state?.offerItem?.offer?.campaign?.publishFrom ?? '')}
                                    disableTo={removeTimeZoneFromDate(state?.offerItem?.offer?.campaign?.publishTo ?? '')}
                                    value={state?.postingDate}
                                    onChange={(e) => handleChangeTimepicker(e as unknown as string, 'postingDate')}
                                    label={t('general.button.reschedule')}/>
                                <Button variant="primary" onClick={reschedulePostRequest}
                                        className="w-fit ml-1">
                                    {t('general.button.save')}
                                </Button>
                            </div> :
                            <h6 className="mb-0">
                                {state?.postingDate ? formatDate(state?.postingDate) : '-'}
                                <FaCalendarAlt className="text-primary font-16 ml-2"/>
                            </h6>
                        }
                        {(statusesToEditTrackinglink.includes(state?.status) && !state?.reschedule) &&
                            <FaEdit
                                onClick={() => setIsRescheduleClicked(prevState => !prevState)}
                                className="font-16 cursor-pointer"/>
                        }
                    </div>
                    <div>{t('brand.campaign.manage.postingDate')}</div>
                    {!!state?.reschedule?.id && <div className="mt-1">
                        <span className="text-primary">
                            {formatDate(state?.reschedule?.postingDate)}
                        </span>
                        <div>{t('brand.campaign.manage.requestedDate')}</div>
                    </div>}
                </Card.Body>
            </Card>
            {!!state?.feedBackLoopDate && <Card className="my-2">
                <Card.Body className="p-3">
                    <h6 className="d-flex align-items-center text-primary">
                        {state?.feedBackLoopDate ? formatDate(state?.feedBackLoopDate) : '-'}
                        <FaCalendarAlt className="text-primary font-16 ml-2"/>
                    </h6>
                    <div>
                        {t('brand.campaign.manage.feedBackLoopEnddate')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="impressions-tooltip">
                                            {t(`brand.campaign.manage.feedBackLoopEnddateTooltip`)}
                                        </Tooltip>}>
                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div>
                </Card.Body>
            </Card>}
            <Card className="my-2">
                <Card.Body className="p-3">
                    <h6>
                        {t('influencer.planner.campaignHashtags')}
                    </h6>
                    <span className="text-muted">
                    </span>
                    <div className="mt-2 word-break-word">
                        {!!state?.offerItem?.offer?.campaign?.hashtags?.length ? (state?.offerItem?.offer?.campaign?.hashtags?.split(' '))?.map((item, i) =>
                                <span key={i} className={`${i > 0 ?
                                    'pl-2' : ''}`}>{item}</span>) :
                            <span className="text-muted">-</span>}
                        {!!(state?.offerItem?.offer?.campaign?.hashtags) && (
                            <BiCopy className="copy-icon ml-1"
                                    onClick={() => handleCopy(state?.offerItem?.offer?.campaign?.hashtags ?? '')}/>
                        )}
                    </div>
                    {!!state?.offerItem?.offer?.campaign?.profileTags?.length && <div className={'mt-3'}>
                        <h6>
                            Tagging
                        </h6>
                        <div className="mt-2 word-break-word">
                            {(state?.offerItem?.offer?.campaign?.profileTags?.split(' '))?.map((item, i) =>
                                <span key={i} className={`${i > 0 ?
                                    'pl-2' : ''}`}>{item}</span>)}
                            <BiCopy className="copy-icon ml-1"
                                    onClick={() => handleCopy(state?.offerItem?.offer?.campaign?.profileTags ?? '')}/>
                        </div>
                    </div>}
                </Card.Body>
            </Card>

            <div className="d-flex align-items-center my-3">
                <TextField
                    label={t('brand.campaign.manage.modal.trackLink')}
                    variant="outlined"
                    value={state?.trackingLink ?? ''}
                    size="small"
                    onChange={(e) => setState(prev => ({...prev, trackingLink: e.target.value}))}
                    disabled={!statusesToEditTrackinglink.includes(state?.status)}
                    className="w-100"
                />
                <OverlayTrigger placement="top"
                                overlay={<Tooltip
                                    id="impressions-tooltip">
                                    {t(`brand.campaign.manage.trackLinkTooltip`)}
                                </Tooltip>}>
                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                </OverlayTrigger>
                {state?.id && statusesToEditTrackinglink.includes(state?.status) &&
                    <Button onClick={() => updateTracklink(state?.id)}
                            disabled={!state?.trackingLink?.length}
                            variant="primary" className="ml-2">
                        {t(`general.button.${!state?.trackingLink ? 'add' : 'save'}`)}
                    </Button>}
            </div>
            {!!state?.postingLink && <div className="d-flex align-items-center my-3">
                <TextField
                    label={t('influencer.reporting.postingLink')}
                    variant="outlined"
                    value={state?.postingLink ?? ''}
                    size="small"
                    disabled
                    className="w-100"
                />
            </div>}
        </div>
    );
};

export default CalendarManageLeftCard;
