import React, {useEffect, useState} from 'react';
import {IOffer} from '../../../../../../model/offer/IOffer';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import HistoryDetail from '../../../../../brand/campaign/book/offer/modal/tabs/history/HistoryDetail';
import {ErrorToast} from '../../../../../../utils/toasters';
import {AgencyService} from '../../../../service';

export function AgencyHistoryTab() {
    const [historyOffers, setHistoryOffers] = useState<IOffer[]>([]);
    const {campaignId, selectedMatchedUser, activeTabModal, campaign} = useSelector((state: IAllStates) => state.offerAgency);

    useEffect(() => {
        if (activeTabModal === 5 && campaign?.offerPlaceholder?.flags?.hasHistory) {
            AgencyService.getOfferHistory(campaignId, selectedMatchedUser?.id)
                .then((response) => {
                    setHistoryOffers(response);
                }).catch(err => ErrorToast(err));
        }
        // eslint-disable-next-line
    }, [selectedMatchedUser]);

    return(
        <>
            {historyOffers.map(offer => (
                <HistoryDetail offer={offer}/>
            ))
            }
        </>
    );
};