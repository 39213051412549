import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import React, {useState} from 'react';
import {Button, Card, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsChevronCompactDown, BsChevronCompactUp} from 'react-icons/bs';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {groupByCampaignId, truncateString} from '../../../../shared/functions/Functions';
import {IPlannerData, IPlannerOffers, IRescheduleNotify} from '../../../influencer/planner/dto/IPlanner';
import {RescheduleCard} from './RescheduleCard';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';

interface IAccordion {
    [key: number]: boolean;
}

interface IProps {
    rescheduleNotify: IRescheduleNotify[];
    offers: IPlannerOffers | undefined;
}

const AgencyPlannerCampaignCard: React.FC<IProps> = ({rescheduleNotify, offers}) => {
    const {t} = useTranslation();
    const [expended, setExpended] = useState<IAccordion>({});
    const history = useHistory();
    const navigateToReportingPage = () => {
      history.push(`${InternalRoutes.AGENCY_ROUTES}/reporting`);
    };
    return (
        <>
            {rescheduleNotify.map((item) => (
                <RescheduleCard key={item?.id} rescheduleData={item}/>
            ))}
            <Card>
                <Card.Body>

                    <Row className="bg-muted default-radius py-2 align-items-end">
                        <Col xs={5}>
                            <h5 className="text-primary text-center">
                                {Object.keys(groupByCampaignId(offers?.data || []))?.length}
                            </h5>
                            <span className="text-center d-block font-weight-semi-bold">
                            {t('general.campaigns')}
                        </span>
                        </Col>
                        <Col xs={3}>
                            <h6 className="text-center">
                                {offers?.totalPlanned?.scheduled}/{offers?.totalPlanned?.totalNbPosts}
                            </h6>
                            <span className="text-center d-block text-info font-10">
                            {t('brand.campaign.manage.scheduled')}
                        </span>
                        </Col>
                        <Col xs={3}>
                            <h6 className="text-center">
                                {offers?.totalPlanned?.approved}/{offers?.totalPlanned?.totalNbPosts}
                            </h6>
                            <span className="text-center d-block font-10 text-primary">
                            {t('brand.campaign.manage.approved')}
                        </span>
                        </Col>

                    </Row>
                    {Object.keys(groupByCampaignId(offers?.data || []))?.map((campaignKey) => {
                        const campaignId = Number(campaignKey);
                        // @ts-ignore
                        const offersData = groupByCampaignId(offers?.data || [])[campaignId];
                        const campaign = offersData[0]?.campaign;
                        const totalNbPosts = (offersData as IPlannerData[])?.reduce((prev, curr) =>
                            prev + curr.totalPlanned.totalNbPosts, 0);
                        const approved = (offersData as IPlannerData[])?.reduce((prev, curr) =>
                            prev + curr.totalPlanned.approved, 0);
                        const scheduled = (offersData as IPlannerData[])?.reduce((prev, curr) =>
                            prev + curr.totalPlanned.scheduled, 0);
                        return (
                            <React.Fragment key={campaignId}>
                                <Accordion className="m-0" id={'planner-accordion'} expanded={expended[campaignId]}
                                           key={campaignId}>
                                    <AccordionSummary className="border-top-0 border-bottom p-0 my-0"
                                                      onClick={() => setExpended(prev => ({
                                                          ...prev,
                                                          [campaignId]: !prev[campaignId],
                                                      }))}>
                                        <Row className={'align-items-center'}>
                                            <Col xs={5} className={'pr-0 d-flex align-items-center'}>
                                                <CustomImageHandler
                                                    photoPath={campaign?.campaignImages?.[0]?.thumbnail}
                                                    thumbnailPath={campaign?.campaignImages?.[0]?.thumbnail}
                                                    classes="small-rounded-logo"
                                                    altTag="Company logo"/>
                                                <span
                                                    className="ml-2 font-weight-semi-bold">
                                            {truncateString(campaign?.title ?? '-', 20)}
                                        </span>
                                            </Col>

                                            <Col xs={3}>
                                        <span className="text-info d-block text-center">
                                            {scheduled ?? 0} /
                                            {' '}
                                            {totalNbPosts}
                                        </span>
                                            </Col>
                                            <Col xs={3}>
                                        <span className="text-primary d-block text-center">
                                            {approved ?? 0} /
                                            {' '}
                                            {totalNbPosts}
                                        </span>
                                            </Col>
                                            <Col xs={1} className={'p-0'}>
                                                {expended[campaignId] ?
                                                    <BsChevronCompactUp size={20} className={'text-muted'}/> :
                                                    <BsChevronCompactDown className={'text-muted'} size={20}/>}
                                            </Col>
                                        </Row>
                                    </AccordionSummary>
                                    <AccordionDetails className="p-0 pt-2">
                                        {offersData?.map((offer: IPlannerData, index: number, currentArray: IPlannerData[]) => {
                                            const {user, totalPlanned} = offer;
                                            const isLastElement = currentArray?.length - 1 === index;
                                            return (
                                                <Row
                                                    className={`align-items-center my-1 ${isLastElement ? 'border-bottom pb-2' : ''}`}
                                                    key={offer?.id}>
                                                    <Col xs={5} className={'d-flex align-items-center pr-0'}>
                                                        <CustomImageHandler
                                                            photoPath={user?.profilePhoto?.thumbnail}
                                                            thumbnailPath={user?.profilePhoto?.thumbnail}
                                                            classes="small-rounded-logo"
                                                            altTag="User Profile"/>
                                                        <span
                                                            className="ml-1 text-muted">{truncateString((user?.displayName || `${user?.firstName} ${user?.lastName}`), 30)}</span>
                                                    </Col>
                                                    <Col xs={3}>
                                                    <span className="text-info d-block text-center">
                                                        {totalPlanned?.scheduled} / {totalPlanned?.totalNbPosts}
                                                    </span>
                                                    </Col>
                                                    <Col xs={3}>
                                                    <span className="text-primary d-block text-center">
                                                        {totalPlanned?.approved} / {totalPlanned?.totalNbPosts}
                                                    </span>
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </React.Fragment>
                        );
                    })}

                    <Button onClick={navigateToReportingPage} variant={'outline-primary'} className={'mt-3 w-100'}>
                        {t('general.button.sendMyReportings')}
                    </Button>
                </Card.Body>
            </Card>
        </>
    );
};

export default AgencyPlannerCampaignCard;
