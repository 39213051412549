import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import {BiLogoPlayStore} from 'react-icons/bi';
import {FaApple} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {Campaign} from '../../../model/campaign/Campaign';
import {ConfirmationModal} from '../../../shared/components/alert/ConfirmationModal';
import usePagination from '../../../shared/hooks/usePagination';
import {IAllStates} from '../../../store/rootReducer';
import {gtmService} from '../../../utils/gtm.service';
import {ErrorToast} from '../../../utils/toasters';
import BillingInfoModal from '../../shared/user/components/BillingInfoModal';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import EditProfileError from './components/EditProfileError';
import DesktopDashboard from './DesktopDashboard';
import {IMyOffers} from './dto/IDashboard';
import MobileDashboard from './MobileDashboard';
import {InfluencerDashboardApi} from './service';

export function InfluencerDashboard() {
    const {t} = useTranslation();
    const {
        user: {
            completionInfo,
            userType,
            hasDevice,
            agencyCompany,
            id,
        }
    } = useSelector((reduxState: IAllStates) => reduxState.auth);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [isBillingModalOpened, setIsBillingModalOpened] = useState(false);
    const [isWarningOpened, setIsWarningOpened] = useState(false);
    const {totalPages, setTotalPages, filterParams, handlePages, setFilterParams} = usePagination({perPage: 10});
    const [myOffers, setMyOffers] = useState<IMyOffers>();
    const appsModalOpenedTime = Number(localStorage.getItem('appsModalOpenedTime') || 0)
    const isMobile = window.innerWidth <= 768;
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (!hasDevice && userType === 'influencer') {
            const diffTime = moment(new Date())?.diff(appsModalOpenedTime, 'day');
            if (diffTime > 7) { // open modal every 7 days || if appsModalOpenedTime is 0, it will enter here because 0 is Jan 01 1970
                setShowPopup(true);
                localStorage.setItem('appsModalOpenedTime', `${new Date().getTime()}`)
            }
        }
    }, [hasDevice, userType, appsModalOpenedTime])

    useEffect(() => {
        InfluencerDashboardApi.getSummary().then(response => {
            setMyOffers(response.myOffers)
        }).catch(error => ErrorToast(error));
    }, []);

    useEffect(() => {
        (filterParams as IMyCampaignsFilterProps)?.sortType && InfluencerDashboardApi.getCampaigns({
            ...filterParams,
            perPage: 9
        }).then(response => {
            setTotalPages(Math.ceil(response.count / response.perPage));
            setCampaigns(response.data);
            gtmService('search');
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [filterParams])

    useEffect(() => {
        if (!(filterParams as IMyCampaignsFilterProps)?.sortType) {
            setFilterParams((prev: any) => ({...prev, sortType: 'DESC', sortBy: 'date'}))
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!completionInfo?.billingInfo && !agencyCompany?.id) {
            setIsWarningOpened(true);
        }
    }, [agencyCompany, completionInfo?.billingInfo])

    return (
        <>
            <Helmet>
                <title>App | influence.vision</title>
            </Helmet>
            <BillingInfoModal show={isBillingModalOpened} closeModal={() => setIsBillingModalOpened(false)}
                              userType={userType}/>
            <ConfirmationModal title={''}
                               description={t('general.updateBillingModal')}
                               showSuccessButton={!completionInfo?.billingInfo}
                               show={isWarningOpened}
                               iconUrl={`/icons/profile/${!completionInfo?.billingInfo ? 'online-shopping' : 'user'}.png`}
                               action={() => {
                                   setIsWarningOpened(false);
                                   setIsBillingModalOpened(true);
                               }}
                               closeModal={() => setIsWarningOpened(false)}/>
            {!completionInfo?.profileInfo && <EditProfileError id={id}/>}
            <MobileDashboard campaigns={campaigns} filterParams={filterParams} setFilterParams={setFilterParams}
                             handlePages={handlePages} totalPages={totalPages} myOffers={myOffers as IMyOffers}/>
            <DesktopDashboard campaigns={campaigns} filterParams={filterParams} setFilterParams={setFilterParams}
                              handlePages={handlePages} totalPages={totalPages} myOffers={myOffers as IMyOffers}/>

            <Modal centered show={showPopup} size="lg"
                   onHide={() => setShowPopup(false)}>
                <div className="position-relative">
                    <img src={`/icons/mobile/${isMobile ? 'mobile-' : ''}website-popup.png`}
                         alt="Website popup to download apps" className="w-100"/>
                    <div className="d-flex align-items-center store-btn-wrapper">
                        <a className="store-button" href="https://apps.apple.com/tr/app/influence-vision/id6474192561"
                           target={'_blank'} rel={'noreferrer'}>
                            <FaApple className="text-white" size={30}/>
                            <div className="d-flex flex-column ml-1">
                                <span className={'text-uppercase text-left font-10'}>Download on the</span>
                                <span className="font-16 text-left">
                                App store
                            </span>
                            </div>
                        </a>
                        <a className="store-button"
                           href="https://play.google.com/store/apps/details?id=com.influencevision.app&hl=en_US"
                           target={'_blank'} rel={'noreferrer'}>
                            <BiLogoPlayStore className="text-white" size={30}/>

                            <div className="d-flex flex-column ml-1">
                                <span className={'text-uppercase text-left font-10'}>Get it on</span>
                                <span className="font-16">Google Play</span>
                            </div>
                        </a>
                    </div>
                </div>
                <Modal.Footer className={'border-top p-0'}>
                    <Button variant={'outline-primary'} onClick={() => setShowPopup(false)}>
                        close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
