import {CancelTokenSource} from 'axios';
import {requests} from '../../../utils';
import {INFLUENCER_REPORTING} from '../../../utils/endpoints';
import {ICampaignsFilter} from './dto/IReporting';

export const InfluencerReportingService = {
    getSummary: () => requests.get(`${INFLUENCER_REPORTING}/summary`),
    getReportingCampaigns: (params: ICampaignsFilter) => requests.get(`${INFLUENCER_REPORTING}/dropdown/campaigns`, params),
    getReports: (params: any) => requests.get(`${INFLUENCER_REPORTING}/campaigns/reports`,
        params),
    getCampaignReports: (id: number) => requests.get(`${INFLUENCER_REPORTING}/campaigns/reports/${id}`),
    createInfluencerReporting: (data: FormData) => requests.post(INFLUENCER_REPORTING, data),
    manipulateInfluencerReporting: (data: FormData, method: 'put' | 'post', cancelToken?: undefined | CancelTokenSource, onUploadProgress?: any) => requests[method](INFLUENCER_REPORTING, data,
        {
            cancelToken: cancelToken,
            onUploadProgress: onUploadProgress
        }),
    deleteReportingImage: (id: number) => requests.delete(`${INFLUENCER_REPORTING}/screenshot/${id}`),
    addSingleReporting: (data: FormData) => requests.post(`${INFLUENCER_REPORTING}/add/reporting`, data),
    getAdditionalReporting: (offerId: number) => requests.get(`${INFLUENCER_REPORTING}/campaigns/additional/reports/${offerId}`),
    addAdditionalReporting: (data: FormData, offerId: number) => requests.post(`${INFLUENCER_REPORTING}/additional/${offerId}`, data),
    updateAdditionalReporting: (data: FormData, offerId: number) => requests.put(`${INFLUENCER_REPORTING}/additional/${offerId}`, data),
    addReview: (review: {
        reply: string
    }, reviewId: number) => requests.post(`${INFLUENCER_REPORTING}/feedback/${reviewId}`, review),
    createExternalLink: (eventId: number, externalLink: string) => requests.post(`${INFLUENCER_REPORTING}/attachment/link/${eventId}`, {
        externalLink: externalLink
    }),
    updateExternalLink: (fileId: number, externalLink: string) => requests.put(`${INFLUENCER_REPORTING}/attachment/link/${fileId}`, {
        externalLink: externalLink
    })
};
