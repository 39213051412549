import React, { CSSProperties } from 'react'
import { pdfImg } from '../../functions/Functions';
import { BiDownload } from 'react-icons/bi';

interface IPdfPlaceholder {
    styleContent?: CSSProperties;
    classContent?: string;
    classIcon?: string;
    isLargeIcon?: boolean,
    fileName: string;
    handleDownload?: (...args: any) => void; 
}

export const getFileType = (fileName: string) => {
  const extension = (fileName?.split('.').pop() || '').toLowerCase();

  switch (extension) {
    case 'pdf':
      return { type: 'pdf', iconClass: 'fi fi-rr-file-pdf text-danger', imgSrc: '' };
    case 'doc':
    case 'docx':
      return { type: 'word', iconClass: 'fi fi-rr-file-word text-primary', imgSrc: '' };
    case 'xls':
    case 'xlsx':
      return { type: 'excel', iconClass: 'fi fi-rr-file-excel text-success', imgSrc: '' };
    case 'ppt':
    case 'pptx':
      return { type: 'powerpoint', iconClass: 'fi fi-rr-file-powerpoint text-warning', imgSrc: '' };
    default:
      return { type: 'other', iconClass: 'fi fi-rr-file text-muted', imgSrc: '' };
  }
};

export default function FilePlaceholder({
  classIcon, 
  classContent, 
  styleContent, 
  isLargeIcon, 
  handleDownload, 
  fileName
}: IPdfPlaceholder) {
  return (
    <div className={`box-shadow d-flex align-items-center justify-content-center ${classContent ?? ''}`}
          style={styleContent}
    >
        {isLargeIcon ? (
          <img src={pdfImg} alt={fileName} className={`${classIcon}`}/>
        ) : (
          <i className={`${getFileType(fileName).iconClass} d-flex ${classIcon ?? 'font-50'}`}/>
        )}

        {handleDownload && (
          <a href="#2" className="cursor-pointer position-absolute"
                                    style={{top: '-5px', right: 20}}
                                    onClick={() => handleDownload?.()}>
              <div className="top-right-icon upload-icon">
                  <BiDownload size={15} className="text-primary"/>
              </div>
          </a>
        )}
    </div>
  )
}
