import axios, {CancelTokenSource} from 'axios';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Form, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import UploadingModal from '../../../planner/components/UploadingModal';
import ReportingHeader from './components/ReportingHeader';
import YourContent from './components/YourContent';
import ReportingBody, {reportingFields} from './components/ReportingBody';
import ReportingScreenshots from './components/ReportingScreenshots';
import {IReportingUpdate, ISelectedEvent} from '../ReportingCampaignCard';
import {InfluencerReportingService} from '../../service';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../../utils/toasters';
import {ConfirmationModal} from '../../../../../shared/components/alert/ConfirmationModal';
import {IPicture} from '../../../../../model/shared/IPicture';
import {InfluencerPlannerService} from '../../../planner/service';
import ContentFilesUpload from '../../../../../shared/components/posting/content-files/ContentFilesUpload';

interface IReportingModalProps {
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    selectedOfferItem: ISelectedEvent;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
}

const initUpdateState = {
    clicks: 0,
    campaignId: 0,
    comments: 0,
    eventId: 0,
    likes: 0,
    offerItemId: 0,
    reach: 0,
    saves: 0,
    shares: 0,
    postingDate: '',
    postingLink: '',
    stickerEngagements: 0,
    swipeUpLinks: 0,
    views: 0,
    watchTime: 0,
};

const ReportingModal = ({
                            isAdditional,
                            isModalOpened,
                            handleCloseModal,
                            selectedOfferItem,
                            setSelectedOfferItem,
                            setUpdateReports,
                        }: IReportingModalProps) => {
    const {t} = useTranslation();
    const [uploadData, setUploadData] = useState({
        loaded: 0,
        from: 0
    });
    const [selectedFile, setSelectedFile] = useState<IPicture>();
    const [isAttachmentClicked, setIsAttachmentClicked] = useState(false);
    const statusesToDisplayButton = ['reported', 'approved'];
    const [updateData, setUpdateData] = useState<IReportingUpdate>(initUpdateState);
    const [source, setSource] = useState<undefined | CancelTokenSource>(axios.CancelToken.source());
    const [isFileUploading, setIsFileUploading] = useState(false);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isNumber = true) => {
        const {name, valueAsNumber, value} = event.target;
        setUpdateData((prev) => ({...prev, [name]: isNumber ? valueAsNumber : value}));
    };

    const cancelApiCall = () => {
        source?.cancel('API request cancelled');
        setSource(undefined);
    };
    const progressHandler = (e: ProgressEvent) => {
        setUploadData({
            loaded: e.loaded,
            from: e.total
        });
    };
    const handleSubmitData = async (event: React.FormEvent) => {
        event.preventDefault();
        // if (!updateData?.file?.length && selectedOfferItem?.event?.status === 'published') {
        //     return WarningToast(t('general.toasts.provideReportingScreenshots')); // if it's published status and file is not sent, don't send API call
        // }
        const formData = new FormData();
        const isUpdate = selectedOfferItem?.event?.status === 'reported';
        const reportItemId = selectedOfferItem?.event?.reportItems?.[0]?.id ?? 0;
        formData.append('body', JSON.stringify({...updateData, reportItemId}));
        if (!!updateData?.file?.length) { // check if it's update and have files in arr
            for (const item of updateData?.file) {
                formData.append('files', (item as string | Blob));
            }
        }
        if (!!updateData?.attachments?.length) {
            for (const item of updateData?.attachments) {
                formData.append('attachments', (item as string | Blob));
            }
        }
        setIsFileUploading(true);
        await InfluencerReportingService.manipulateInfluencerReporting(formData, isUpdate ? 'put' : 'post', source?.token as any, (e: ProgressEvent) => progressHandler(e)).then(response => {
            if (response === '') {
                handleCloseModal();
                setUpdateReports(prev => !prev);
                setSelectedOfferItem(undefined);
                setUpdateData(initUpdateState);
                SuccessToast(t('influencer.reporting.successUpdatedReporting'));
            }
        }).catch(error => ErrorToast(error)).finally(() => setIsFileUploading(false));
    };
    const getReportItemValue = () => {
        const storage: any = {};
        reportingFields.forEach(reportingKey => {
            storage[reportingKey] = (selectedOfferItem?.event?.reportItems[0] as any)?.[reportingKey];
        });
        return storage;
    };
    const deleteImageHandler = async () => {
        if (isAttachmentClicked) {
            await InfluencerPlannerService.deleteAttachment(selectedFile?.id ?? 0).then(response => {
                SuccessToast(t('influencer.reporting.successDeleteImg'));
                setIsAttachmentClicked(false);
                setUpdateReports(prev => !prev);
            }).catch(error => ErrorToast(error));
        } else {
            await InfluencerReportingService.deleteReportingImage(selectedFile?.id ?? 0).then(response => {
                if (response === '') {
                    SuccessToast(t('influencer.reporting.successDeleteImg'));
                    setUpdateReports(prev => !prev);
                    setSelectedOfferItem((prev: any) => ({
                        ...prev,
                        event: {
                            ...prev?.event,
                            reportItems: [],
                        },
                    }));
                }
            }).catch(error => ErrorToast(error));
        }
    };

    useEffect(() => {
        if (!!selectedOfferItem?.event?.id && !isAdditional) {
            setUpdateData(prev => ({
                ...prev,
                campaignId: selectedOfferItem?.campaign?.id,
                eventId: selectedOfferItem?.event?.id,
                offerItemId: selectedOfferItem?.offerItem?.id,
                postingDate: selectedOfferItem?.event?.postingDate,
                postingLink: selectedOfferItem?.event?.postingLink,
                ...getReportItemValue(),
            }));
        }
        // eslint-disable-next-line
    }, [selectedOfferItem?.event?.id, isAdditional]);

    useEffect(() => {
        if (!selectedOfferItem?.event) {
            setUpdateData(prev => ({...prev, file: []}));
        }
        // eslint-disable-next-line
    }, [selectedOfferItem]);

    useEffect(() => {
        if (!!selectedFile?.id) {
            handleCloseModal();
        }
        // eslint-disable-next-line
    }, [selectedFile?.id]);
    const isFeedbackLoopDisabled = selectedOfferItem?.campaign?.isReviewed;
    const isFormDisabled = isFeedbackLoopDisabled && !statusesToDisplayButton?.includes(selectedOfferItem?.event?.status);

    return (
        <>
            <UploadingModal show={isFileUploading} uploadData={uploadData} closeModal={() => setIsFileUploading(false)}
                            cancelApiCall={cancelApiCall}/>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Form onSubmit={handleSubmitData}>
                    {selectedOfferItem?.event && <Modal.Body>
                        <Row>
                            <ReportingHeader updateData={updateData} selectedOfferItem={selectedOfferItem}/>
                            {selectedOfferItem?.event?.status !== 'reported' && isFeedbackLoopDisabled && (
                                <>
                                    <YourContent selectedAttachments={selectedOfferItem?.event?.attachments}
                                            offerEvent={selectedOfferItem?.event}   
                                            eventId={selectedOfferItem?.event?.id} isUploadPossible
                                            setSelectedOfferItem={setSelectedOfferItem}
                                            setUpdateData={setUpdateData}
                                            setIsAttachmentClicked={setIsAttachmentClicked}
                                            setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                            isButtonDisabled={isFormDisabled}
                                    />
                                    {/* <ContentFilesUpload 
                                        setSelectedOfferItem={setSelectedOfferItem}
                                        setUpdateData={setUpdateData}
                                        offerEvent={selectedOfferItem?.event}    
                                    /> */}
                                </>
                           )}
                            <ReportingBody
                                isButtonDisabled={isFormDisabled}
                                socialPlatform={selectedOfferItem?.event?.offerItem?.socialPlatform}
                                updateData={updateData} handleChange={handleChange}/>
                            <ReportingScreenshots
                                setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                                status={selectedOfferItem?.event?.status}
                                isButtonDisabled={isFormDisabled}
                                setUpdateData={setUpdateData}
                                reportItems={selectedOfferItem?.event?.reportItems}/>
                        </Row>
                    </Modal.Body>}
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={handleCloseModal}>
                            {t('general.button.cancel')}
                        </Button>
                        {(statusesToDisplayButton?.includes(selectedOfferItem?.event?.status) || !isFeedbackLoopDisabled) &&
                            <Button variant="primary" type="submit">
                                {t(`general.button.${selectedOfferItem?.event?.status === 'reported' ? 'updateReport' : 'report'}`)}
                            </Button>}
                    </Modal.Footer>
                </Form>
            </Modal>

            <ConfirmationModal
                title={t('influencer.planner.deleteImage')}
                description={`${t('influencer.planner.deleteImageDesc')} ${selectedFile?.externalLink ?? selectedFile?.title ?? selectedFile?.originalName}`}
                action={deleteImageHandler}
                show={!!selectedFile?.id} closeModal={() => setSelectedFile(undefined)}
            />
        </>
    );
};

export default ReportingModal;
