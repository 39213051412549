import {Tab, Tabs} from '@material-ui/core';
import {Accordion, AccordionDetails} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import React, {useEffect, useRef, useState} from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {MdOutlineExpandMore} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {SideChatBody} from '../../../app/brand/campaign/messages/components/chat/SideChatBody';
import {IMessageContent, IMessageQuery} from '../../../app/brand/campaign/messages/dto/IMessage';
import {MessageService} from '../../../app/brand/campaign/messages/service';
import {CampaignService} from '../../../app/brand/campaign/service';
import {UserRoles} from '../../../enum/UserRoles';
import {Campaign} from '../../../model/campaign/Campaign';
import {messageSlice} from '../../../store/general/messageSlice';
import {redDotsSlice} from '../../../store/influencer/redDotsSlice';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import {getCorrectFilterKeys} from '../../functions/Functions';
import useOnScreen from '../../hooks/useOnScreen';
import {CustomAutocomplete} from '../Autocomplete';
import {CustomImageHandler} from '../CustomImageHandler';
import {a11yProps} from '../MaterialTabsComponents';
import {MessageSelectedBubble} from './MessageSelectedBubble';

interface IExpandedChat {
    [key: number]: boolean;
}

export const MessageBubble = () => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const prevUnreadMessageListResponse = useRef(null); // Store the previous response
    const prevUnreadMessageResponse = useRef(null); // Store the previous response
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const {unreadIDs} = useSelector((state: IAllStates) => state.message);
    const isBrand = userType.includes('brand');
    const isAgency = ['agency', 'agency_master'].includes(userType);
    const [currTab, setCurrTab] = useState<number | null>((isBrand || isAgency) ? 0 : null);
    const [expandedChats, setExpandedChats] = useState<IExpandedChat>({})
    const isBrandCampaignScreen = (location?.pathname?.includes('/brand/campaign/') && window.innerWidth <= 991) || (userType === UserRoles.INFLUENCER && window.innerWidth <= 991);
    const openedChatsStored = localStorage.getItem('openedChats');
    const [openedChats, setOpenedChats] = useState<IMessageContent[]>([]);
    const lastMessageRef = useRef<HTMLElement>(null);
    const isReachedBottom = useOnScreen(lastMessageRef);
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [inboxMessages, setInboxMessages] = useState<IMessageContent[]>();
    const [filterParams, setFilterParams] = useState({
        page: 1,
        perPage: 10,
        title: '',
    });
    const [filterParamsInbox, setFilterParamsInbox] = useState<IMessageQuery>({
        page: 1,
        perPage: 15,
        types: (isBrand || isAgency) ? getCorrectFilterKeys(0, isBrand) : null,
    });

    const [countOfMessages, setCountOfMessages] = useState(0);
    const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState(0);

    const changeCampaignHandler = (paramKey: string, paramValue: string | number) => {
        setFilterParamsInbox(prev => ({...prev, campaignId: !!paramValue ? +paramValue : ''}));
    };

    const openCampaign = (message: IMessageContent) => {
        if (window.innerWidth < 540) { // if it's mobile, just go to chat
            history.push(userType === 'brand_master' ? `/brand/campaign/messages/${message?.campaignId}` : '/influencer/messages', {
                threadId: message?.threadMetaData[0]?.id,
            });
        } else {
            const copy = [...openedChats];
            const isClickedChatOpened = copy?.some(oneMessage => oneMessage?.id === message?.id);
            if (!isClickedChatOpened) {
                if (copy?.length > 2) {
                    copy[0] = message;
                } else {
                    copy.push(message);
                }
                localStorage.setItem('openedChats', JSON.stringify(copy));
                setOpenedChats(copy);
                const idToFind = inboxMessages?.findIndex(msg => msg.id === message.id) as number;
                const copyInboxMessages = [...inboxMessages as IMessageContent[]];
                copyInboxMessages[idToFind] = { // REFACTOR THIS
                    ...copyInboxMessages[idToFind],
                    messages: copyInboxMessages[idToFind]?.messages?.map((item, index) => {
                        if (index === 0) {
                            return {
                                ...item,
                                messageMetadata: item.messageMetadata.map((metadata, metIndex) => {
                                    if (metIndex === 0) {
                                        return {
                                            ...metadata,
                                            isRead: true,
                                        };
                                    }
                                    return {
                                        ...metadata,
                                    };
                                }),
                            };
                        }
                        return {
                            ...item,
                        };
                    }),
                };
                setInboxMessages(copyInboxMessages);
            }
        }
    };

    const closeOpenedChat = (id: number) => {
        const copy = [...openedChats];
        const filteredState = copy?.filter(openedChat => openedChat?.id !== id);
        localStorage.setItem('openedChats', JSON.stringify(filteredState));
        setOpenedChats(filteredState);
    };

    useEffect(() => {
        const fetchData = () => {
            MessageService.getUnreadMessages({
                campaignId: filterParamsInbox?.campaignId,
            })
                .then(response => {
                    const {
                        exploreCount,
                        exploreBarterCount,
                        explorePaidCount,
                        receivedNotOpenedOffers,
                        receivedNotOpenedPaidOffers,
                        receivedNotOpenedBarterOffers
                    } = response?.redDots;

                    const currentData = {
                        explore: exploreCount,
                        offers: receivedNotOpenedOffers,
                        exploreBarter: exploreBarterCount,
                        explorePaid: explorePaidCount,
                        paidOffers: receivedNotOpenedPaidOffers,
                        barterOffers: receivedNotOpenedBarterOffers
                    };

                    // Compare new response with the previous one
                    if (JSON.stringify(prevUnreadMessageResponse.current) !== JSON.stringify(currentData)) {
                        // If different, update state and ref
                        //  @ts-ignore
                        prevUnreadMessageResponse.current = currentData;
                        dispatch(redDotsSlice.actions.setRedDots(currentData));
                    }

                    // Set unread messages count
                    setNumberOfUnreadMessages(+response?.unreadMessages);
                })
                .catch(error => ErrorToast(error));
        };

        // Call fetchData initially
        fetchData();

        // Set up polling interval (e.g., every 15 seconds)
        const pollingInterval = 15000;
        const intervalId = setInterval(fetchData, pollingInterval);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [filterParamsInbox?.campaignId]);

    useEffect(() => {
        if (['influencer', 'agency', 'agency_master'].includes(userType)) {
            MessageService.getInfluencerCampaigns(filterParams).then(response => {
                setCampaigns(response.data?.map(campaign => ({
                    ...campaign,
                    title: (!!campaign?.title?.length ? campaign?.title : '-') ?? '-',
                })));
            }).catch((error) => ErrorToast(error));
        } else {
            CampaignService.getCampaigns(filterParams)
                .then((response) => {
                    setCampaigns(response.data?.map(campaign => ({
                        ...campaign,
                        title: (!!campaign?.title?.length ? campaign?.title : '-') ?? '-',
                    })));

                })
                .catch((error) => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams), userType]);

    useEffect(() => {
        MessageService.getInboxMessages(filterParamsInbox).then(response => {
            const {data} = response;
            if (data) {
                setCountOfMessages(+response?.count);
                setInboxMessages(data);
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [JSON.stringify(filterParamsInbox)]);
    useEffect(() => {
        if (isReachedBottom) {
            const {perPage} = filterParamsInbox;
            const valueToUpdate = (perPage + 10) < countOfMessages ? (perPage + 10) : countOfMessages < 15 ? 15 : countOfMessages;
            setFilterParamsInbox(prev => ({
                ...prev,
                perPage: valueToUpdate,
            }));
        }
        // eslint-disable-next-line
    }, [isReachedBottom]);

    useEffect(() => {
        if (openedChatsStored) {
            const parsed: IMessageContent[] = JSON.parse(openedChatsStored);
            parsed?.forEach((message) => {
                message.isFromLocalStorage = true;
            });
            setOpenedChats(parsed);
        }
    }, [openedChatsStored]);

    const markAllAsRead = async () => {
        await MessageService.markAllAsRead().then(response => {
            if (response) {
                MessageService.getInboxMessages(filterParamsInbox).then(newData => {
                    const {data} = newData;
                    if (data) {
                        setCountOfMessages(+newData?.count);
                        setInboxMessages(data);
                        setNumberOfUnreadMessages(0);
                    }
                }).catch(error => ErrorToast(error));
            }
        }).catch(error => ErrorToast(error));
    }


    useEffect(() => {

        const fetchData = () => {
            MessageService.getUnreadMessageList()
                .then(response => {
                    // Only dispatch if the response is different from the previous one
                    if (JSON.stringify(prevUnreadMessageListResponse.current) !== JSON.stringify(response)) {
                        prevUnreadMessageListResponse.current = response;
                        dispatch(messageSlice.actions.setUnreadIDs(response));
                    }
                })
                .catch(err => ErrorToast(err));
        };


        // Call fetchData immediately when the component mounts
        fetchData();

        // Polling interval in milliseconds (e.g., every 15 seconds)
        const pollingInterval = 15000;
        const intervalId = setInterval(fetchData, pollingInterval);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, [dispatch]);


    const getCorrectSize = (chats: number) => {
        const fourSize = [0, 2];
        if (fourSize.includes(chats)) {
            return 4;
        }
        if (chats === 1) {
            return 6;
        }
        return 3;
    };
    const getAccordionColSize = (chats: number) => {
        if (chats === 3) {
            return 3;
        }
        if (chats === 2) {
            return 4;
        }
        if (chats === 1) {
            return 6;
        }
        return 12;
    };
    const handleNavigateMessageScreen = (isBrand = false) => {
        if (isBrand) {
            history.push(`/brand/campaign/messages/${filterParamsInbox?.campaignId}`)
        } else {
            history.push(`/influencer/messages/${filterParamsInbox?.campaignId}`)
        }
    }
    return (
        <div
            className={`global-chat-bubble ${isBrandCampaignScreen ? 'mobile' : ''} ${userType === UserRoles.INFLUENCER ? 'd-md-flex d-none' : ''}`}>
            <div className="wrapper" style={{
                gridTemplateColumns: `repeat(${openedChats?.length + 1}, 300px)`
            }}>

                {openedChats?.map((message, index) => {
                    const isExpanded = !!expandedChats?.[message?.id];
                    return (
                        <div className={`pl-md-0 position-relative`}
                             key={message?.id}>
                            <MessageSelectedBubble isMessageBubble={true} index={index}
                                                   closeOpenedChat={closeOpenedChat}
                                                   setOpenedChats={setOpenedChats}
                                                   isExpanded={isExpanded}
                                                   onChatClick={() => {
                                                       setExpandedChats(prev => ({
                                                           ...prev,
                                                           [message?.id]: !isExpanded
                                                       }));
                                                       dispatch(messageSlice.actions.setUnreadIDs(unreadIDs.filter(chatId => chatId !== message?.id))) // clear NEW badge
                                                   }}
                                                   isUnread={unreadIDs?.some(msgId => msgId === message?.id)}
                                                   key={message?.id}
                                                   message={message}/>
                        </div>
                    )
                })}
                <div className="position-relative">
                    <Accordion className="chat-accordion  position-absolute">
                        <AccordionSummary
                            expandIcon={<MdOutlineExpandMore className="rotate-180" size={25}/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className={`${!!numberOfUnreadMessages ? 'bg-primary-lighter' : ''}`}
                        >
                            <div className="d-flex align-items-center">
                                <CustomImageHandler
                                    altTag="My logo"
                                    classes="user-img-rounded mr-2 box-shadow-angle"
                                    photoPath={user?.profilePhoto?.thumbnail as string}
                                />
                                <span className="font-weight-semi-bold mr-2">
                                    {t('general.messaging')}
                                </span>
                                {!!numberOfUnreadMessages &&
                                    <span className="calendar-icon">
                                        {numberOfUnreadMessages}
                                    </span>
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="py-0 px-0">
                            {campaigns?.length ? <div
                                className={`d-flex align-items-center justify-content-between ${isBrand ? '' : 'pb-3'} px-2`}>
                                <CustomAutocomplete dataArray={campaigns} isWithImage={false} inputLabel="title"
                                                    disableClearable={false}
                                                    isMultiple={false} changeEventKey="title"
                                                    setFilterState={setFilterParams}
                                                    queryFilterKey="title"
                                                    inputLabelTranslation={t('general.campaignFilters')}
                                                    handleChangeParams={changeCampaignHandler}/>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`red-tooltip tooltip-right`}>
                                            Mark All as read
                                        </Tooltip>
                                    }
                                >
                                    <Button variant="primary" className="ml-1" onClick={markAllAsRead}>
                                        <i className="fi fi-rr-eye-crossed d-flex font-18"/>
                                    </Button>
                                </OverlayTrigger>
                            </div> : null}
                            {(isAgency || isBrand) && <Box sx={{width: '100%'}}>
                                <Box className="w-100">
                                    <Tabs value={currTab} onChange={(_, value) => {
                                        setCurrTab(value);
                                        setFilterParamsInbox(prev => ({
                                            ...prev,
                                            types: getCorrectFilterKeys(value, isBrand)
                                        }));
                                    }}
                                          aria-label="basic tabs example"
                                          TabIndicatorProps={{style: {background: 'var(--primary)'}}}>
                                        <Tab label={isBrand ? t('brand.campaign.manage.management') :
                                            t('brand.campaign.manage.campaigns')} {...a11yProps(0)} />
                                        <Tab label={t('brand.campaign.manage.contentCreators')} {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                            </Box>}
                            <div className="scrollable-div p-1">
                                {!!inboxMessages?.length ? inboxMessages?.map(message => (
                                    <div key={message?.id}
                                         onClick={() => {
                                             openCampaign(message);
                                             setExpandedChats(prev => ({
                                                 ...prev,
                                                 [message?.id]: true
                                             }));
                                         }}
                                         className="w-100 mt-3 user-chat align-items-start">
                                        <SideChatBody message={message}/>
                                    </div>
                                )) : <p className="mt-3 px-1 text-muted">
                                    {t('general.noMessageForCampaign')}
                                </p>}
                                <span ref={lastMessageRef}/>
                            </div>
                            {!!inboxMessages?.length && (!!filterParamsInbox?.campaignId) &&
                                <div className="bg-muted py-3 text-center">
                                    <a href="#" className="font-weight-semi-bold"
                                       onClick={() => handleNavigateMessageScreen(isBrand)}>
                                        {t('general.seeAllMessages')}
                                    </a>
                                </div>}
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};
