import React from 'react';
import {Card, Col, OverlayTrigger, ProgressBar, Row, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import Flag from 'react-world-flags';
import {Platforms} from '../../../../../../../../model/platform/Platforms';
import {getCorrectSocialUrl} from '../../../../../../../../shared/functions/Functions';
import {numberFormatter} from '../../../../../../../../utils/helpers/numberFormater';
import {IPlatformDataArray, IStatistics} from '../../../dto/IAudienceDataModal';
import AudienceModashData from './AudienceModashData';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import { priceSplitter } from '../../../../../../../../utils/helpers/priceSplitter';

interface IAudienceDataProps {
  audienceData?: IPlatformDataArray;
}

export const platformIcons = {
  averageComments: 'fi fi-rr-comment',
  averageConcurrentViewers: 'fi fi-rr-street-view',
  averageReelPlays: 'fi fi-rr-file-video',
  averageViews: 'fi fi-rr-eye',
  engagementRate: 'fi fi-rr-bullseye-pointer',
  followers: 'fi fi-rr-following',
  averageLikes: 'fi fi-rr-social-network',
  averagePostEngagements: 'fi fi-rr-cursor-finger',
  averagePostImpressions: 'fi fi-rr-photo-video',
  subscribers: 'fi fi-rr-users',
  totalWatchTime: 'fi fi-rr-clock-three',
  uniqueVisitors: 'fi fi-rr-arrows-repeat-1',
  totalPosts: 'fi fi-rr-mode-landscape',
  allPlatforms: 'fi fi-rr-data-transfer',
  totalPageViews: 'fi fi-rr-browser',
  newsletterSubscribers: 'fi fi-rr-envelope-open-text',
  averageDownloads: 'fi fi-rr-cloud-download-alt',
  averagePlays: 'fi fi-rr-file-video',
  totalEpisodes: 'fi fi-rr-film',
  viewMinutes: 'fi fi-rr-time-forward-sixty',
  liveHoursPerMonth: 'fi fi-rr-video-camera',
  credibility: 'fi fi-rr-user',
  likersCredibility: 'fi fi-rr-heart',
};

const sexes = ['male', 'female'];

export const disablePlatformFormatting = ['engagementRate'];

const AudienceData = ({audienceData}: IAudienceDataProps) => {
  const {audienceMetricAge} = useSelector((states: IAllStates) => states.enums);
  const {t} = useTranslation();

  const getCorrectIconName = (platformKey: string) => {
    return (platformIcons as any)?.[platformKey] as string;
  };

  const platformKeysMap: { [key: number]: string[] } = {
    1: ['followers', 'averageLikes', 'averageComments', 'averageReelPlays', 'totalPosts', 'engagementRate'], // Instagram
    2: ['subscribers', 'averageLikes', 'averageComments', 'averageViews', 'totalWatchTime', 'engagementRate'], // Youtube
    4: ['followers', 'averageLikes', 'averageComments', 'averageViews', 'totalPosts', 'engagementRate'], // Tiktok
    5: ['subscribers', 'averageDownloads', 'averagePlays', 'totalEpisodes', 'newsLetterSubscribers'], // Podcast
    7: ['subscribers', 'uniqueVisitors', 'totalPageViews', 'totalPosts', 'newsLetterSubscribers'], // Blog
    6: ['followers', 'subscribers', 'averageConcurrentViewers', 'viewMinutes', 'liveHoursPerMonth'], // Twitch
    9: ['followers', 'averageLikes', 'averagePostEngagements', 'averagePostImpressions', 'totalPosts'], // Linkedin
  };

  const sortAudienceKPIs = (platformId: number, platformKeys: string[]) => {
    const currentPlatform = platformKeysMap?.[platformId];
    return currentPlatform ? currentPlatform.concat(platformKeys.filter(key => !currentPlatform.includes(key))) : platformKeys;
  };

  return (
    <div className="p-0 mt-3 mt-md-0 mb-4 mb-md-0">
      <Row className="custom-row">
        <Col xs={12} className="mb-3">
          <a target="_blank"
             className="d-flex align-items-center"
             href={`${getCorrectSocialUrl(audienceData?.mainPlatform?.platformEnum as string, audienceData?.statistic?.title ?? '', audienceData?.statistic?.externalLink ?? '')}`}
             rel="noreferrer">
            <img src={audienceData?.mainPlatform?.path} alt="Platform icon"/>
            <span className="ml-1 mb-0">
                            @{audienceData?.statistic?.title}
                        </span>
          </a>
        </Col>
        <Col xl={4}>
          <h6 className="mb-3">{t('brand.campaign.book.offer.modal.country')}</h6>
          {audienceData?.audiences?.country?.map(country => (
              <Row className="my-1 align-items-center" key={`${country?.id}-${country?.metric}`}>
                <Col className="pl-0" xs={1}>
                  <OverlayTrigger placement="right"
                                  overlay={<Tooltip
                                    id="country-tooltip">{t(`general.countries.${country?.metric}`)}</Tooltip>}>
                    <div className="country-flag ">
                      <Flag code={country?.metric}/>
                    </div>
                  </OverlayTrigger>

                </Col>
                <Col xs={9} className="d-flex align-items-center">
                  <ProgressBar variant="primary" className="progress-sm w-100 bg-primary-lighter"
                               now={country?.value}/>
                </Col>
                <Col xs={2} className="pl-0">
                  <p className="text-black-50 d-flex mb-0">
                    {priceSplitter(+country?.value.toFixed(2))}%
                  </p>
                </Col>
              </Row>
            ),
          )}
        </Col>
        <Col xl={4}>
          <h6 className="my-3">{t('brand.campaign.book.offer.modal.age')}</h6>
          {audienceData?.audiences.age?.map((age, index) => (
              <Row className="my-1 mb-2 align-items-center" key={age?.id}>
                <Col className="px-0" xs={2}>
                  <h6 className="mb-0 d-flex">{t(`general.age_groups.${audienceMetricAge[index]}`)}</h6>
                </Col>
                <Col xs={8} className="d-flex align-items-center">
                  <ProgressBar variant="info" className="progress-sm w-100 bg-info-lighter"
                               now={age?.value}/>
                </Col>
                <Col xs={2} className="pl-0">
                  <p className="text-muted mb-0 d-flex">
                    {priceSplitter(+age?.value.toFixed(2))}%
                  </p>
                </Col>
              </Row>
            ),
          )}
        </Col>
        <Col xl={4}>
          <h6 className="my-3">{t('brand.campaign.book.offer.modal.gender')}</h6>
          {sexes.map((gender, index) => {
            const isFemale = gender === 'female';
            return (
              <Row className="my-1 mb-2 align-items-center" key={index}>
                <Col className="px-0" xs={2}>
                  <h6 className="mb-0 d-flex">{t(`brand.campaign.book.offer.modal.${gender}`)}</h6>
                </Col>
                <Col xs={8} className="d-flex align-items-center">
                  <ProgressBar variant={isFemale ? 'info' : 'primary'}
                               className={`progress-sm w-100 ${isFemale ? 'bg-info-lighter' : 'bg-primary-lighter'}`}
                               now={Number(audienceData?.audiences?.gender[index]?.value)}/>
                </Col>
                <Col xs={2} className="pl-0">
                  <p className="text-muted mb-0 d-flex">
                    {priceSplitter(+audienceData?.audiences?.gender[index]?.value.toFixed(2)! ?? 0)}%
                  </p>
                </Col>
              </Row>);
          })}
        </Col>
        {sortAudienceKPIs(audienceData?.mainPlatform?.id ?? 0, Object.keys(platformIcons))?.map(platformKey => {
          if (!audienceData?.mainPlatform?.[platformKey as keyof Platforms]) {
            return null;
          }
          // console.log(platformKey, 'platformKey')
          return (
            <Col xs={6} md={2} className="pl-md-0 mt-3 custom-col-sm-6" key={platformKey}>
              <Card className="h-100">
                <Card.Body className="text-center">
                  <i className={`${getCorrectIconName(platformKey)} mb-1 text-primary font-20 d-flex justify-content-center`}/>
                  <h5 className="mb-0">
                    {!!audienceData?.statistic?.[platformKey as keyof IStatistics] ?
                      (disablePlatformFormatting.includes(platformKey) ?
                        (Number((audienceData?.statistic?.[platformKey as keyof IStatistics] as number) ?? 0)?.toFixed(2) === '0.00' ? 'n.a.' :  priceSplitter(+Number((audienceData?.statistic?.[platformKey as keyof IStatistics] as number) ?? 0)?.toFixed(2))+' %')
                        :
                        numberFormatter(
                          (audienceData?.statistic?.[platformKey as keyof IStatistics] as number) ?? 0))
                      : 'n.a.'}
                  </h5>
                  {t(`general.statistics.${platformKey}`)}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
        {!!audienceData?.statistic.data &&
            <AudienceModashData audienceData={audienceData} getCorrectIconName={getCorrectIconName}/>}
      </Row>
    </div>
  );
};

export default AudienceData;
