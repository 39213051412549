import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, OverlayTrigger, Table} from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import {useTranslation} from 'react-i18next';
import {BsExclamationOctagon} from 'react-icons/bs';
import {GoDotFill} from 'react-icons/go';
import {Link, useHistory} from 'react-router-dom';
import {Campaign} from '../../../../model/campaign/Campaign';
import {ITotalPlanned} from '../../../../model/offer/IOfferItems';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../shared/components/EmptyState';
import {Pagination} from '../../../../shared/components/Pagination';
import {
    formatDate,
    getCampaignNumbers,
    getCorrectColor,
    getCorrectFilterTitle, getPlatformIcon,
    truncateString
} from '../../../../shared/functions/Functions';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IMyCampaignItem, IPlannerPlatforms} from '../../../influencer/campaign/dto/IMyCampaigns';
import {INewEvent} from '../../../influencer/planner/components/CreateEventModal';
import {statuses} from '../../../influencer/planner/components/PlannerCalendarListView';
import {InfluencerPlannerService} from '../../../influencer/planner/service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

const statusesWithIcon = ['proposed'];

interface Props {
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>;
    setCreateEvent: React.Dispatch<React.SetStateAction<INewEvent>>;
    isToDoSelected: boolean;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    setSelectedEvent: React.Dispatch<SetStateAction<iManageList | undefined>>;
    filterData: { page: number };
    totalPages: number;
    handlePages: (page: number) => void;
    plannerPlatforms: IPlannerPlatforms[];
}

const AgencyListView = ({
                            isToDoSelected,
                            setCreateEvent,
                            setIsCreateModalOpened,
                            setIsProposalOpened,
                            setSelectedEvent,
                            plannerPlatforms, totalPages, handlePages, filterData,
                            setIsEditModalOpened,
                        }: Props) => {
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const {t} = useTranslation();
    const handleOpenModal = async (eventId: string) => {
        const data = await InfluencerPlannerService.getPlannerModalData(eventId, selectedInfluencer?.id);
        setSelectedEvent(data);
        // IV-3112
        // if (data.status === 'reported') {
        //     history.push('/agency/reporting', {
        //         offerId: data?.id
        //     })
        // }
        if (data.status === 'proposed') {
            setIsProposalOpened(true);
        } else {
            setIsEditModalOpened(true);
        }
    };

    const handleOpenPlannerModal = (eventId: number, offerItemId: number, campaign: IMyCampaignItem, acceptedUser: User) => {
        if (!eventId) {
            setCreateEvent({
                campaign: campaign as unknown as Campaign,
                campaignId: campaign?.id,
                acceptedUser: acceptedUser,
                offerItemId,
                postingDate: new Date().toISOString(),
                caption: null,
            });
            setIsCreateModalOpened(true);
        } else {
            handleOpenModal(eventId as unknown as string);
        }
    };
    return (
        <>
            {plannerPlatforms?.filter(platform => !!platform?.campaigns?.length)?.map(platform => {
                const {acceptedUser, campaigns} = platform;
                return (
                    <Table className="table-head-bg opacity-1 mt-3 w-max-content w-md-100" responsive
                           key={acceptedUser?.id}>
                        <thead className="table-head-bg">
                        <tr>
                            <th>
                                <div className="d-flex align-items-center w-fit-content text-center">
                                    <div className="d-flex align-items-center">
                                        <CustomImageHandler photoPath={acceptedUser?.profilePhoto?.thumbnail}
                                                            altTag={'User Profile'}
                                                            classes={'user-img-rounded'}/>
                                        <div className="d-flex flex-column ml-1 align-items-start">
                                                    <span className="font-weight-semi-bold text-dark">
                                                        {acceptedUser?.displayName}
                                                    </span>
                                            <span className="text-primary font-weight-normal">
                                                        {campaigns?.reduce((prev, curr) => (curr?.offers?.[0]?.totalPlanned?.totalNbPosts || 0) + prev, 0)} Postings
                                                    </span>
                                        </div>
                                    </div>

                                </div>
                            </th>
                            <th>
                                <div className="text-center">
                                    {t('influencer.planner.date')}
                                </div>
                            </th>
                            {statuses?.map(offerItem => {
                                return (
                                    <th key={offerItem}>
                                        <div className="text-center d-flex flex-column align-items-center">
                                                     <span className="font-weight-semi-bold text-dark">
                                                        {(acceptedUser?.totalPlanned)?.[offerItem as keyof ITotalPlanned] as number}
                                                         /
                                                         {acceptedUser?.totalPlanned?.totalNbPosts}
                                                    </span>
                                            {statusesWithIcon?.includes(offerItem) ?
                                                <div className="mx-1 d-flex align-items-center">
                                                    {offerItem === 'proposed' &&
                                                        <BsExclamationOctagon className="proposed-icon"/>}
                                                    <span className="ml-1 text-dark font-weight-normal">
                                                    {getCorrectFilterTitle(offerItem, t)}
                                                </span>
                                                </div> : <span key={offerItem}
                                                               className={`calendar-button-dot d-flex align-items-center
-                                            mx-1 text-dark font-weight-normal ${getCorrectColor(offerItem)}`}>
                                                                                   {getCorrectFilterTitle(offerItem, t)}
                                            </span>
                                            }
                                        </div>
                                    </th>
                                );
                            })}
                            <th>{t('influencer.planner.actions')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {campaigns?.map((campaign) => {
                            return (
                                <React.Fragment key={campaign?.id}>
                                    <tr className="text-dark">
                                        <td>
                                            <Link className="d-flex align-items-center cursor-pointer text-dark"
                                                  to={`/agency/campaign/${campaign?.id}/overview`}
                                            >
                                                <CustomImageHandler
                                                    photoPath={campaign?.campaignImages?.[0]?.thumbnail as string}
                                                    altTag="Campaign logo" classes="campaign-icon"/>
                                                <span className="font-weight-bold ml-1">
                                                            {truncateString(campaign?.title || '', 35)}
                                                        </span>
                                            </Link>
                                        </td>
                                        <td></td>
                                        {statuses?.map(offerStatus => (
                                            <td key={offerStatus}>
                                                <div className="text-center">
                                                    {getCampaignNumbers(offerStatus as keyof ITotalPlanned, campaign?.offers?.[0]?.totalPlanned)}
                                                    /
                                                    {campaign?.offers[0]?.totalNbPosts ?? 0}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>

                                    {campaign?.offers?.[0]?.offerItems?.map((offerItem) => (
                                        <React.Fragment key={offerItem?.id}>
                                            {offerItem?.offerItemEvents?.map((event, index) => (
                                                <tr key={event?.id ?? index}
                                                    className={`${event?.status === 'proposed' || !event?.status ? 'bg-propose-light' : ''}`}>
                                                    <td className="pl-5 d-flex align-items-center">
                                                        <div
                                                            className="d-flex align-items-center cursor-pointer"
                                                            onClick={
                                                                () => handleOpenPlannerModal(event?.id, offerItem?.id, campaign, acceptedUser)}>
                                                            <img src={offerItem?.socialPlatform?.path}
                                                                 alt={offerItem?.socialPlatform?.name}/>
                                                            <span
                                                                className="font-weight-semi-bold text-dark ml-1">
                                               {t(`general.platforms.${offerItem?.socialPlatform?.name}`)}
                                            </span>
                                                        </div>
                                                        {event?.status === 'proposed' &&
                                                            <div className="ml-2 d-flex align-items-center">
                                                                <BsExclamationOctagon
                                                                    className="proposed-icon"/>
                                                                <span className="pl-1">Proposed</span>
                                                            </div>}
                                                        {!event?.id && <OverlayTrigger overlay={
                                                            <Tooltip id="tooltip-top">
                                                                {t('influencer.planner.notScheduled')}
                                                            </Tooltip>
                                                        }
                                                        >
                                                            <div className="ml-2 cursor-pointer">
                                                                <img src="/icons/tick-mark.png"
                                                                     alt="tick-mark icon"/>
                                                            </div>
                                                        </OverlayTrigger>}
                                                    </td>
                                                    <td style={{minWidth: 55}}>
                                                        <p className="text-center my-2">
                                                            {!!event?.date ? formatDate(event?.date) : ''}
                                                        </p>
                                                    </td>
                                                    {statuses?.map(status => (
                                                        <td key={status}>
                                                            {getPlatformIcon(
                                                                event?.totalPlanned,
                                                                status as keyof ITotalPlanned,
                                                            )}
                                                        </td>
                                                    ))}
                                                    <td>
                                                        <div className="position-relative w-fit-content w-100">
                                                            {event?.eventNotifications?.[0]?.isUnread ?
                                                                <GoDotFill size={15}
                                                                           style={{
                                                                               right: -5
                                                                           }}
                                                                           className="text-danger top-right-bullet bg-white radius-50"
                                                                /> : ''}
                                                            <Button
                                                                className="w-100"
                                                                variant={`${!event?.id || event?.status === 'proposed' ? 'primary'
                                                                    : 'outline-primary'}`}
                                                                onClick={() => handleOpenPlannerModal(event?.id, offerItem?.id, campaign, acceptedUser)}>
                                                                {t(!event?.id || event?.status === 'proposed' ? 'general.button.schedule' : 'general.open')}
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            )
                        })}
                        </tbody>
                    </Table>
                )
            })}
            {(plannerPlatforms.every(item => !item.campaigns.length)) &&
                <div className="border-top">
                    <EmptyState desc={t('agency.planner_empty_state')}/>
                </div>}
            {(totalPages > 1 && !isToDoSelected) && <div className="d-flex justify-content-end">
                <Pagination
                    page={filterData.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            </div>}
        </>
    );
};

export default AgencyListView;
