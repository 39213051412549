import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {Card} from 'react-bootstrap';
import EmptyState from '../../../../../../../../shared/components/EmptyState';
import { priceSplitter } from '../../../../../../../../utils/helpers/priceSplitter';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};

interface IAudienceDataChartProps {
    title: string;
    description: string;
    chartData?: any;
    chartDates?: string[];
}

export default function AudienceDataChart({title, description, chartData, chartDates}: IAudienceDataChartProps) {
    const valueFormatter = {
        scales: {
            y: {
                ticks: {
                    callback: function(value: any) {
                        return priceSplitter(value);
                    }
                }
            }
        }
    };
    
    return (
        <Card className="mb-3">
            <Card.Body>
                <h6>{title}</h6>
                <p className="text-muted">{description}</p>
                {!!chartDates?.length ?
                    <Line options={{...options, scales: valueFormatter.scales}} data={{labels: chartDates, datasets: chartData ?? []}}/> : <EmptyState/>}
            </Card.Body>
        </Card>
    );
}
