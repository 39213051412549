import moment from 'moment';
import React, {useState} from 'react';
import {Badge, Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {BsBookmark, BsFillBookmarkFill} from 'react-icons/bs';
import {FiInfo} from 'react-icons/fi';
import {useDispatch, useSelector} from 'react-redux';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';
import {clearHtmlFromString, formatDate} from '../../../../shared/functions/Functions';
import {redDotsSlice} from '../../../../store/influencer/redDotsSlice';
import {IAllStates} from '../../../../store/rootReducer';
import BillingInfoModal from '../../../shared/user/components/BillingInfoModal';
import {IExploreItem} from '../dto/Explore';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {AdditionalItem} from '../../offer/components/AdditionalItem';
import {gtmService} from '../../../../utils/gtm.service';
import {setSelectMatchedUser} from '../../../../store/agency/agencyOfferSlice';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {Tooltip as MuiTooltip} from '@mui/material';

interface IExploreCardProps {
    handleAddToFavorite: (exploreId: number) => void;
    exploreItem: IExploreItem;
    setExploreFilters?: React.Dispatch<any>;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setCampaignId: React.Dispatch<React.SetStateAction<string>>;
    isAgencyCard?: boolean;
}

const ExploreCard = ({
                         setExploreFilters,
                         handleAddToFavorite,
                         exploreItem,
                         setShowModal,
                         setCampaignId,
                         isAgencyCard,
                     }: IExploreCardProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const {
        user: {
            completionInfo,
            userType,
            birthdate,
            agencyCompany,
        },
    } = useSelector((reduxState: IAllStates) => reduxState.auth);
    const [isBillingModalOpened, setIsBillingModalOpened] = useState(false);
    const [isWarningOpened, setIsWarningOpened] = useState(false);
    const {
        matchedInfluencers,
        title,
        campaignImages,
        platforms,
        adDescription,
        company,
        id,
        isFavorite,
        isPicked,
        isNew,
        isCharity,
    } = exploreItem;
    const hasExclusivity = exploreItem?.hasExclusivity ? 'exclusivity' : false;
    const hasBylaws = exploreItem?.hasByLaws ? 'bylaws' : false;
    const hasBrandedContent = !!exploreItem?.brandedContent ? 'branded_content' : false;
    const hasUsageRights = !!exploreItem?.usageRights ? 'usage_right' : false;
    const contentToDisplay = [hasUsageRights, hasExclusivity, hasBrandedContent, hasBylaws]?.filter(item => !!item);
    const isMobile = window.innerWidth <= 600;
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    const [openTooltip, setOpenTooltip] = useState(false);
    const onSubmit = (isNew = false, isCharity = false) => {
        if (moment().diff(birthdate, 'years') < 15 || (!completionInfo?.billingInfo && !agencyCompany?.id)) {
            setIsWarningOpened(true);
            return;
        }
        setShowModal(true);
        setCampaignId(String(id));
        if (isNew && setExploreFilters) { // update left sidebar & trigger update for updating cards
            dispatch(redDotsSlice.actions.setRedDots({
                ...redDots, explore: redDots?.explore > 1 ? redDots?.explore - 1 : 0,
                exploreBarter: isCharity ? redDots?.exploreBarter! - 1 : redDots.exploreBarter,
            }))
            setTimeout(() => {
                setExploreFilters((prev: any) => ({...prev, updatedAt: new Date().getTime()}));
            }, 500);
        }
        // User for agency offer modal
        if (matchedInfluencers) {
            dispatch(setSelectMatchedUser(matchedInfluencers[0]));
        }
        gtmService('select_content');
    };
    const goToOBriefingOverview = () => {
        if (moment().diff(birthdate, 'years') > 15 && (completionInfo?.billingInfo || !!agencyCompany?.id)) {
            isAgencyCard ?
                history.push(`/agency/campaign/${id}/overview`) :
                history.push(`/influencer/campaign/${id}/overview`);
        } else {
            setIsWarningOpened(true);
        }
    };
    const nbOfMatchedInfluencers = (exploreItem?.matchedInfluencers?.length || 0)
    return (
        <>
            <BillingInfoModal show={isBillingModalOpened} closeModal={() => setIsBillingModalOpened(false)}
                              userType={userType}/>
            <ConfirmationModal title={''}
                               description={t(!completionInfo?.billingInfo && !agencyCompany?.id ? 'general.updateBillingModal' : 'general.toYoung')}
                               showSuccessButton={!completionInfo?.billingInfo}
                               show={isWarningOpened}
                               iconUrl={`/icons/profile/${!completionInfo?.billingInfo && !agencyCompany?.id ? 'online-shopping' : 'user'}.png`}
                               action={() => {
                                   setIsWarningOpened(false);
                                   if (!agencyCompany?.id) {
                                       setIsBillingModalOpened(true);
                                   }
                               }
                               }
                               closeModal={() => setIsWarningOpened(false)}/>
            <Card className="h-100">
                <Card.Body
                    className={`d-flex flex-column justify-content-between ${isPicked ? 'border-warning-right' : ''}`}>
                    <div className="d-flex flex-column flex-md-row">
                        <div onClick={goToOBriefingOverview} className="w-md-25 position-relative cursor-pointer">
                            <CustomImageHandler photoPath={campaignImages?.[0]?.thumbnail}
                                                altTag={campaignImages?.[0]?.title}
                                                classes="w-100 default-radius img-cover aspect-ratio-sm-16-9"/>

                            {isPicked && (
                                <img
                                    src="/icons/medal.png"
                                    className="d-md-none place-top-right"
                                    alt="Medal icon"
                                />
                            )}
                            {isNew && (
                                <Badge variant={'info'} className="place-top-left">
                                    New
                                </Badge>
                            )}
                        </div>
                        <div className="ml-md-2 mt-3 mt-md-0 w-md-75">
                            <div className="d-flex align-items-start justify-content-between">
                                <h4 onClick={goToOBriefingOverview}
                                    className="cursor-pointer overflow-auto">{isCharity && (
                                    <>
                                        <div className="d-inline-block">(</div>
                                        <div
                                            className="text-primary-dark d-inline-block">{t('general.button.barter_deal')}</div>
                                        <div className="d-inline-block">)</div>
                                    </>
                                )} {title}
                                </h4>
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <div className="cursor-pointer mb-1"
                                         onClick={() => handleAddToFavorite(id)}
                                    >
                                        {isFavorite ? <BsFillBookmarkFill className="text-primary" size={20}/> :
                                            <BsBookmark size={20}/>}
                                    </div>
                                    {isPicked && (
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{show: 250, hide: 400}}
                                            overlay={<Tooltip id="button-tooltip">
                                                influence.vision {t('influencer.explore.picks')}
                                            </Tooltip>}
                                        >
                                            <img
                                                src="/icons/medal.png"
                                                className="d-none d-md-flex cursor-pointer"
                                                alt="Medal icon"
                                            />
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                            <p className="text-muted mb-1">
                                {t('brand.campaign.briefing.application_deadline')}: <span
                                className="text-primary">{formatDate(exploreItem?.date ?? '')}</span>
                            </p>
                            <div className="row align-items-center">
                                {platforms?.map((platform) => (
                                    <div className="col-6 pl-md-0 my-1 d-flex align-items-center"
                                         key={platform?.socialPlatform?.id}>
                                        <img src={platform?.socialPlatform?.path}
                                             alt={`Icon ${platform?.socialPlatform?.name}`}/>
                                        <span
                                            className="text-muted mx-2"> {t(`general.platforms.${platform?.socialPlatform?.name}`)}</span>
                                        <h6 className="text-primary mb-0 mr-3">x{platform?.nbPosts}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="mt-3">
                        <p className="font-weight-bold">
                            {t('influencer.explore.contentBriefing')}
                        </p>
                        <span onClick={goToOBriefingOverview} className="intro pt-0 cursor-pointer">
                            {clearHtmlFromString(adDescription ?? '')}
                        </span>
                    </div>
                    <div className="mt-3">
                        <div className="d-flex align-items-center">
                            <span className="font-weight-bold">
                                {t('influencer.explore.additionItems')}
                            </span>
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="impressions-tooltip">
                                                {t(`influencer.reporting.additionItemsTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </div>
                        <div className="d-flex align-items-center overflow-auto">
                            {!!contentToDisplay?.length ? getAdditionalItems(contentToDisplay) :
                                <div className="my-1 d-flex align-items-center">
                                    <CustomImageHandler photoPath="/icons/additionalItems/no_aditional_offers.svg"
                                                        altTag="Empty-additional"
                                                        classes="social-icon m-1"
                                    />
                                    <p className="mb-0 text-muted">{t('influencer.explore.noAdditionOfferItems')}</p>
                                </div>
                            }
                        </div>
                        {isAgencyCard &&
                            <div className="d-flex align-items-center w-100 mt-1">
                                <CustomImageHandler photoPath={company?.profilePhoto?.thumbnail}
                                                    classes="campaign-icon img-cover rounded-circle"
                                                    altTag="User Profile"/>
                                <span className="font-weight-bold ml-2 ">by {company?.displayName}</span>
                            </div>
                        }
                        <div className="d-flex align-items-center justify-content-between mt-3">
                            {isAgencyCard ?
                                <>
                                    <h6 dangerouslySetInnerHTML={{__html: t('agency.matched_influencer')}}
                                        className="mb-0"/>
                                    <div className="d-flex align-items-center campaign-user-list mr-auto ml-1">
                                        {exploreItem?.matchedInfluencers?.slice(0, isMobile ? 3 : 10)?.map((user, i) => (
                                            <OverlayTrigger
                                                key={user?.id}
                                                overlay={
                                                    <Tooltip id="tooltip-top" className="cursor-pointer">
                                                        {!!user.displayName ? user.displayName : (user.firstName + ' ' + user.lastName)}
                                                    </Tooltip>
                                                }
                                            >
                                                <img
                                                    src={user?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                                    style={{marginLeft: `${i * 1.3}rem`}}
                                                    className="campaign-card-img "
                                                    alt="Campaign placeholder"
                                                    onError={(e: any) => (
                                                        // eslint-disable-next-line
                                                        (e.target.onerror = null),
                                                            (e.target.src = '/icons/UserPlaceholder.png')
                                                    )}
                                                />
                                            </OverlayTrigger>
                                        ))}
                                        {nbOfMatchedInfluencers > (isMobile ? 3 : 10) &&
                                            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                                <MuiTooltip id="white-tooltip"
                                                            disableFocusListener
                                                            disableHoverListener
                                                            disableTouchListener
                                                            PopperProps={{
                                                                disablePortal: true,
                                                            }}
                                                            onClose={() => setOpenTooltip(false)}
                                                            open={openTooltip}
                                                            title={<>
                                                                {exploreItem?.matchedInfluencers?.slice(isMobile ? 3 : 10)?.map(user => (
                                                                    <div className="mx-1 my-2" key={user?.id}>
                                                                        <div className="d-flex align-items-center">
                                                                            <CustomImageHandler
                                                                                classes="user-img-rounded"
                                                                                photoPath={user?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                                                                altTag={`${user?.profilePhoto?.name}`}/>
                                                                            <h6 className="ml-1 text-dark mb-0">{user?.firstName}</h6>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </>}
                                                            placement="right-end"
                                                            className="cursor-pointer"
                                                >
                                            <span
                                                className="cursor-pointer icon-round bg-dark font-weight-semi-bold campaign-card-img"
                                                onClick={() => setOpenTooltip(prevState => !prevState)}
                                                style={{
                                                    width: 33,
                                                    marginLeft: `${isMobile ? 4 : 10 * 1.3}rem`,
                                                }}>+{nbOfMatchedInfluencers - (isMobile ? 3 : 10)}</span>
                                                </MuiTooltip>
                                            </ClickAwayListener>
                                        }
                                    </div>
                                </>
                                :
                                <div className="d-flex align-items-center w-65">
                                    <CustomImageHandler photoPath={company?.profilePhoto?.thumbnail}
                                                        classes="campaign-icon img-cover rounded-circle"
                                                        altTag="User Profile"/>
                                    <span className="font-weight-bold ml-2 ">by {company?.displayName}</span>
                                </div>
                            }
                            <div className="col-auto pr-0">
                                {isCharity && (
                                    <OverlayTrigger placement="top"
                                                    overlay={<Tooltip
                                                        id="impressions-tooltip">
                                                        {t(`influencer.explore.barterTooltip`)}
                                                    </Tooltip>}>
                                        <FiInfo size={14} className="cursor-pointer mr-2"/>
                                    </OverlayTrigger>
                                )}
                                <Button onClick={() => onSubmit(isNew, isCharity)}
                                        variant={isCharity ? 'primary-dark' : 'primary'}>
                                    {isCharity ? t('general.button.barter_deal') : t('general.button.submit_offer')}
                                </Button>
                                {isNew && <span className="notification-bullet offer"></span>}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export const getAdditionalItems = (contentToDisplay: (string | boolean)[]) => {
    return (
        <>
            {contentToDisplay?.map((additional, index) => {
                return (
                    <div className="m-1" key={index}>
                        <AdditionalItem itemName={additional as string}/>
                    </div>
                );
            })}
        </>
    );
};

export default ExploreCard;